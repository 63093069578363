//
//** List Widget 1 Component
//

//== Component Base
@mixin m-build-component--widget1-base() {
    .m-widget1 {  	
    	padding: array-get($emma--widget, layout, self, space);

        &.m-widget1--paddingless {
            padding: 0;
        }

    	.m-widget1__item {
    		padding: 1.1rem 0;

    		.m-widget1__title {
    			font-size: get-font-size(regular, '++');
    			font-weight: get-font-weight(bolder);
    			margin-bottom: 0;
    		}

    		.m-widget1__desc {
    			display: inline-block;
    			margin-top: 0.21rem;
    			font-size: get-font-size(regular);
					font-weight: get-font-weight(normal);
    		}

    		.m-widget1__number {
    			font-size: get-font-size(regular, '++++');
    			font-weight: get-font-weight(boldest);
    		}

    		&:first-child {
    			padding-top: 0.5rem; 
    		}

    		&:last-child {
    			padding-bottom: 0.5rem; 
    		}
			}
			
			&.test-ab-resume {

				.m-widget1__item {

					.col.test-version-name {
						display: flex;
						align-items: center;

						> i {
							margin-right: 10px;
						}
					}

					.m-widget1__title {
						font-size: get-font-size(regular, '+++');
						font-weight: get-font-weight(bolder);
					}

					.m-widget1__desc {
						margin-top: 0;
						margin-left: 10px;
						text-transform: uppercase;
					}

					.m-widget1__column {
						font-size: get-font-size(regular);
						font-weight: get-font-weight(boldest);
						margin-bottom: 0;
					}

					&.winner-test-ab {

						.m-widget1__title {
							font-weight: get-font-weight(boldest);
						}

						.m-widget1__desc {
							font-weight: get-font-weight(bolder);
						}
					}
				}
			}
    }
}

//== Component Skin
@mixin m-build-component--widget1-skin() {
    $skin: get-default-skin(); 
       
    .m-widget1__item {
    	.m-widget1__title {
    		color: get-color($skin, regular, '+');
    	}

    	.m-widget1__desc {
				color: get-color($skin, regular, '----');
    	}

    	border-bottom: 0.07rem dashed get-color($skin, panel);

    	&:last-child {
    		border-bottom: 0;
			}
			
			&.winner-test-ab {

				.m-widget1__desc {
					color: get-brand-color();
				}

				.m-widget1__number {
					color: get-brand-color();
				}
			}
    }     
}

//== Build Component Base
@include m-build-component--widget1-base();

//== Build Component - Light Skin
@include m-build-component--widget1-skin();