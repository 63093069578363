//
//** Widget 17 Component
//

//== Component Base

@mixin m-build-component--widget17-base() {
    .m-widget17 { 
        .m-widget17__visual {
            .m-widget17__chart {
                position: relative;
                padding-top: 8rem;
            }
        }
         
        .m-widget17__stats {
            display:table;
            width: 90%; 
            margin: 0 auto 1rem auto;

            // Items
            .m-widget17__items {
                display: table-cell;           
                padding: 0 0.35rem;  

                &.m-widget17__items-col1 {
                    width: 50%;
                }  

                &.m-widget17__items-col2 {
                    width: 50%;
                }      

                &:last-child {
                    margin-bottom: 0.71rem;
                }        

                .m-widget17__item {              
                    position: relative;
                    margin-top: -3.87rem;  
                    background-color: white; 
                    margin-bottom: 4.5rem;     
                    height: 11rem;
                    padding-bottom: 10px;

                    .m-widget17__icon {
                        display: block;       
                        margin-left: 1.5rem;
                        padding-top: 1.5rem;
                        
                        > i {
                            font-size: 2rem;    
                        } 
                    }

                    .m-widget17__subtitle {
                        display: block;
                        margin-top: 1.8rem;
                        margin-left: 1.5rem; 
                        font-size: get-font-size(regular, '++');
                        font-weight: get-font-weight(bolder);                        
                    } 

                    .m-widget17__desc {
                        display: block;
                        margin-left: 1.43rem;      
                        margin-left: 1.5rem;        
                        font-size: get-font-size(regular, '-');
                        font-weight: get-font-weight(regular);
                    }      

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }           
        }
    }        
} 
 
@mixin m-build-component--widget17-skin($skin) {
    @include component-skin(m-widget17, $skin) {

        .m-widget17__stats {
            .m-widget17__items {
                .m-widget17__item {
                    @include shadow(get-shadow($skin, '---')); 

                    .m-widget17__subtitle {
                        color: get-color($skin, regular, '-');
                    }
                    .m-widget17__desc {
                        color: get-color($skin, regular, '--'); 
                    }            
                }
            }    
        }      
    }
}

//== Build Component Base
@include m-build-component--widget17-base();

//== Build Component - Light Skin
@include m-build-component--widget17-skin(light);
