//
//** List Widget 1 Component
//

//== Component Base
@mixin m-build-component--widget14-base() {
	.m-widget14 {        
			padding: array-get($emma--widget, layout, self, space); 	
    }
}

//== Build Component Base
@include m-build-component--widget14-base();
