//
//** Modal Customization
// 

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--modal-base() {
    //== Base
    .modal {

        .modal-content {
            border: 0;
            @include border-radius(0);
            @include rounded {
                @include border-radius(array-get($m--border-radius, general));
            }

            // -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

            -webkit-transform: scale(0);
                    transform: scale(0);
                    opacity: 0;

            -webkit-transform-origin: center;
                    transform-origin: center;

            -webkit-animation: modal-emma-open 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) both;
                    animation: modal-emma-open 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) both;

            .modal-header {
                padding: 25px;
                
                .modal-title {
                    font-weight: get-font-weight(bold);
                    font-size: get-font-size(regular, '++');
                }

                .close  {
                    line-height: 0;
                    @include m-generate-close-emma-icon();
                    font-size: 0;

                    &:before {
                        font-size: get-font-size(icon, '-');
                    }
                    
                    outline:none !important;
                    span {
                        display: none;    
                    }
                }
            }

            .modal-body {
                padding: 25px;
            }

            .modal-footer {
                padding: 25px;
            }
        }

        &.emma-modal {
            .modal-content {
                .modal-header {
                    border-bottom: inherit;
                    padding-bottom: 10px;

                    .modal-title {
                        font-weight: get-font-weight(bold);
                        font-size: get-font-size(regular, '+++++');
    
                        > i {
                            font-size: get-font-size(icon, '++++');
                            vertical-align: middle;
                            margin-right: 10px;
                            margin-bottom: 4px;
                        }
                    }
                }
            }

            .emma-modal--options {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                > .option-item {
                    flex: 1 1 auto;
                    // Add width 1% and flex-basis auto to ensure that button will not wrap out
                    // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
                    width: 1%;
                    cursor: pointer;
                    margin: 5px;
                    min-width: 110px;
                    max-width: 115px;
                    @include border-radius(.25rem);
                    @include transition(all .15s ease-in-out);

                    span {
                        color: get-color(light, regular);
                        @include transition(all .15s ease-in-out);
                    }

                    .option-item-img {
                        text-align: center;
                        padding: 20px 30px 10px;

                        > img {
                            width: 100%;
                        }
                    }

                    .option-item-text {
                        text-align: center;
                        padding: 10px 10px 15px;

                        > span:first-child {
                            display: block;
                        }
                    }

                    &:hover {
                        background-color: get-brand-color();
                        color: get-brand-inverse-color();

                        span,
                        span[class^='m--font-'] {
                            color: get-brand-inverse-color() !important;
                        }

                        .option-item-img {

                            > img {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }

                    &:only-child {
                        max-width: 180px;
                    }
                }
            }

            .emma-modal--description {
                text-align: center;
            }
        }

        .modal-md {
            max-width: 500px;
        }

        .modal-xl {
            max-width: 1100px;
        }
    }

    .modal-bg-emma {
        background-color: rgba(255, 255, 255, .75);
        opacity: 0;

            -webkit-animation: modal-emma-bg 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) both;
                    animation: modal-emma-bg 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) both;
    }

    .modal-open {
        overflow: auto !important;
        padding: 0 !important;
    }

    @-webkit-keyframes modal-emma-open {
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes modal-emma-open {
        100% {
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes modal-emma-bg {
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes shake {
        10%,
        90% {
          -webkit-transform: translate3d(-2px, 0, 0);
                  transform: translate3d(-2px, 0, 0);
        }
        20%,
        80% {
          -webkit-transform: translate3d(4px, 0, 0);
                  transform: translate3d(4px, 0, 0);
        }
        30%,
        50%,
        70% {
          -webkit-transform: translate3d(-8px, 0, 0);
                  transform: translate3d(-8px, 0, 0);
        }
        40%,
        60% {
          -webkit-transform: translate3d(8px, 0, 0);
                  transform: translate3d(8px, 0, 0);
        }
      }
      @keyframes shake {
        10%,
        90% {
          -webkit-transform: translate3d(-2px, 0, 0);
                  transform: translate3d(-2px, 0, 0);
        }
        20%,
        80% {
          -webkit-transform: translate3d(4px, 0, 0);
                  transform: translate3d(4px, 0, 0);
        }
        30%,
        50%,
        70% {
          -webkit-transform: translate3d(-8px, 0, 0);
                  transform: translate3d(-8px, 0, 0);
        }
        40%,
        60% {
          -webkit-transform: translate3d(8px, 0, 0);
                  transform: translate3d(8px, 0, 0);
        }
      }

}

//== Component Skin
@mixin m-build-component--modal-skin($skin) {
    .modal {    
        .modal-content {
            @include shadow(get-shadow($skin));

            .modal-header {
                .modal-title {
                    color: get-color($skin, regular, '+');
                }

                .close  {
                    span {
                        color: get-color($skin, regular);
                    }
                }
            }
        }   
    }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--modal-base();

//== Component Skin - Light
@include m-build-component--modal-skin(light);