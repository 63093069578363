.btn-share-google {
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: url('../../assets/media/img/google/btn_google_signin_light_normal_web@2x.png') no-repeat scroll 0 0 transparent;
  background-size: 234px 52px;
  background-position: center;
  width: 234px;
  height: 52px;
  border: none;

  &:hover {
    background: url('../../assets/media/img/google/btn_google_signin_light_focus_web@2x.png') no-repeat scroll 0 0 transparent;
    background-size: 234px 52px;
  }

  &:active {
    background: url('../../assets/media/img/google/btn_google_signin_light_pressed_web@2x.png') no-repeat scroll 0 0 transparent;
    background-size: 234px 52px;
  }
}
