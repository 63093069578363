//
//** Global Mixins
//

//== Lineawesome icon
@mixin la-emma-icon($icon) {
  font-family: 'Line Awesome Free';
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &::before {
    content: '#{$icon}';
  }
}

//== Lineawesome icon
@mixin la-emma-icon-self($icon) {
  font-family: 'Line Awesome Free';
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: '#{$icon}';
}

//== Close icon
@mixin m-generate-close-emma-icon($self: null) {
  @if $self == true {
    @include la-emma-icon-self(array-get($m--emma-icon-codes, close));
  } @else {
    @include la-emma-icon(array-get($m--emma-icon-codes, close));
  }
}

@mixin m-generate-tick-emma-icon($self: null) {
  @if $self == true {
    @include la-emma-icon-self(array-get($m--emma-icon-codes, tick));
  } @else {
    @include la-emma-icon(array-get($m--emma-icon-codes, tick));
  }
}

//== Arrow down icon
@mixin m-generate-arrow-emma-icon($dir, $self: null) {
  @if $dir == down {
    @if $self == true {
      @include la-emma-icon-self(array-get($m--emma-icon-codes, down));
    } @else {
      @include la-emma-icon(array-get($m--emma-icon-codes, down));
    }
  }

  @if $dir == up {
    @if $self == true {
      @include la-emma-icon-self(array-get($m--emma-icon-codes, up));
    } @else {
      @include la-emma-icon(array-get($m--emma-icon-codes, up));
    }
  }

  @if $dir == left {
    @if $self == true {
      @include la-emma-icon-self(array-get($m--emma-icon-codes, left));
    } @else {
      @include la-emma-icon(array-get($m--emma-icon-codes, left));
    }
  }

  @if $dir == right {
    @if $self == true {
      @include la-emma-icon-self(array-get($m--emma-icon-codes, right));
    } @else {
      @include la-emma-icon(array-get($m--emma-icon-codes, right));
    }
  }
}

//== Custom Button Size

// Button sizes
@mixin button-size-custom($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius);
}