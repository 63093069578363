//== Component Base
@mixin m-build-component--emmasegmentation-base() {
  .emma-segmentation {
    padding: 0 10px 10px;

    .rowSegment,
    .rowSegmentSub {
      display: flex;
      flex: 1;

      &-boolean {
        display: flex;
        padding-right: 10px;
      }

      &-text {
        display: flex;
        padding-top: 8px;
        padding-right: 10px;

        > span {
          font-size: 1rem;
        }
      }

      &-filters {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        min-width: 0;

        > *:not(:first-child) {
          margin-left: 10px;
        }
      }

      &-buttons {
        display: flex;
        flex-shrink: 0;
        align-self: stretch;
        margin-left: auto;
        padding-left: 10px;
      }

      &-inputs {
        display: flex;
        flex: 1;
        min-width: 0;

        > * {
          flex: 1;
          min-width: 0;

          &:not(:first-child) {
            margin-left: 10px;
            flex: 0 1 auto;
          }
        }

        > button {
          flex: 0 1 auto;
        }
      }

      &-input-subTag {
        flex-basis: 100%;
        margin-top: 10px;
        margin-left: 0px !important;
      }
    }

    .segment {
      padding: 10px;
      position: relative;

      // &:not( :first-of-type ) {
      //   margin-top: 10px;
      // }

      &::before {
        content: '';
        position: absolute;
        display: block;
        border: solid transparent;
        border-width: 1px;
        border-top-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-right-style: none;
        top: 0;
        bottom: 0;
        left: 0;
        width: 5px;
        transition: all 0.3s ease-in-out;
      }

      &-subFilter {
        margin-top: 10px;
        margin-left: 10px;
        padding: 5px 0 5px 10px;
        position: relative;

        .rowSegmentSub {
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          display: block;
          border: solid transparent;
          border-width: 1px;
          border-top-style: solid;
          border-bottom-style: solid;
          border-left-style: solid;
          border-right-style: none;
          top: 0;
          bottom: 0;
          left: 0;
          width: 5px;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .betweenSegment-boolean {
      padding: 10px;
      position: relative;
      border-left-width: 1px;
      border-left-style: solid;
      border-left-color: transparent;
      transition: all 0.3s ease-in-out;
    }

    .addSegment,
    .showSegment {
      display: flex;
      margin-top: 10px;
      align-items: center;

      > *:not(:first-child) {
        margin-left: 10px;
      }

      &.segmentActionBar {
        @include transition(array-get($m-config-header, header, self, transition, general));
        align-items: flex-start;

        &.segmentActionBar--header {
          position: -webkit-sticky;
          position: sticky;
          z-index: 9;
          padding: 10px 0;
          top: 190px;
          margin-top: 0;
          border-bottom-width: 1px;
          border-bottom-style: solid;
          background-color: #fff;
        }

        .segmentActionBar--side {
          display: flex;
          align-items: center;
          margin-left: auto;

          > *:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }

    &.emma-segmentation-compact-mode {
      > .row {
        > .col-md-12 {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .rowSegment,
      .rowSegmentSub {
        &-filters {
          display: block;

          > *:not(:first-child) {
            display: block;
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }

      .addSegment {
        display: block;
      }

      .addSegment,
      .showSegment {
        &.segmentActionBar {
          position: relative;
          top: 0;
        }
      }
    }
  }

  .explorePortlet {
    .emma-segmentation {
      .addSegment {
        &.segmentActionBar {
          top: 120px;
        }
      }
    }
  }

  .m-header--fixed.m-header--minimize-on .m-portlet {
    .emma-segmentation {
      .addSegment {
        &.segmentActionBar {
          @include transition(array-get($m-config-header, header, self, transition, hide));

          top: 125px;
        }
      }
    }

    .explorePortlet {
      .emma-segmentation {
        .addSegment {
          &.segmentActionBar {
            @include transition(array-get($m-config-header, header, self, transition, hide));

            top: 55px;
          }
        }
      }
    }
  }

  .emma-rules {
    .rowSegment-filters {
      > * {
        display: block;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      > div {
        &.rowSegment-inputs:not(.rowSegment-input-relativeDate) {
          display: flex;

          > *:first-child {
            flex: 1;
          }
        }
      }
    }
  }

  @include tablet-and-mobile {
    .m-header--fixed.m-header--minimize-on .m-portlet {
      .emma-segmentation {
        .addSegment {
          &.segmentActionBar {
            @include transition(array-get($m-config-header, header, self, transition, hide));

            top: 140px;
          }
        }
      }

      .explorePortlet {
        .emma-segmentation {
          .addSegment {
            &.segmentActionBar {
              @include transition(array-get($m-config-header, header, self, transition, hide));

              top: 70px;
            }
          }
        }
      }
    }
  }

  .importDataModalSegmentContainer {
    padding-top: 20px;

    .importDataModalSegmentStatus {
      margin-bottom: 5px;

      > i {
        vertical-align: middle;
      }

      .importDataModalSegmentStatusDetail {
        font-size: 0.8rem;
      }
    }
  }
}

@mixin m-build-component--emmasegmentation-skin($skin) {
  .emma-segmentation {
    .segment {
      &::before {
        border-top-color: get-color($skin, panel);
        border-bottom-color: get-color($skin, panel);
        border-left-color: get-color($skin, panel);
      }

      &:hover {
        &::before {
          border-top-color: get-brand-color();
          border-bottom-color: get-brand-color();
          border-left-color: get-brand-color();
          width: 8px;
        }
      }

      &-subFilter {
        &::before {
          border-top-color: get-color($skin, panel);
          border-bottom-color: get-color($skin, panel);
          border-left-color: get-color($skin, panel);
        }

        &:hover {
          &::before {
            border-top-color: get-brand-color();
            border-bottom-color: get-brand-color();
            border-left-color: get-brand-color();
            width: 8px;
          }
        }
      }
    }

    .betweenSegment-boolean {
      border-left-color: get-color($skin, panel);

      &:hover {
        border-left-color: get-brand-color();
      }
    }

    .addSegment {
      &.segmentActionBar {
        background-color: get-color($skin, box);
        border-bottom-color: get-color($skin, panel);
      }
    }

    &.emma-segmentation-compact-mode {
      .addSegment {
        &.segmentActionBar {
          background-color: transparent;
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmasegmentation-base();

//== Component Skin
@include m-build-component--emmasegmentation-skin(light);
