//== Component Base
@mixin m-build-component--emmainput-base() {
  label.emma-input-file__label,
  label.emma-input-file-image__label,
  label.emma-input-file-content__label {
    margin-bottom: 0;
  }

  .emma-input-file__input,
  .emma-input-file-image__input,
  .emma-input-file-content__input {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .emma-input-file-image__preview img {
    max-width: 100%;
    max-height: 100%;
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmainput-base();

//== Component Skin
