 //
//** List Widget 4e [EMMA version] Component
//

//== Component Base
@mixin m-build-component--widget4e-ver-base() {
    .m-widget4e-ver {

        .m-widget4e-ver__info {
            display: table-cell;
            width: 100%;
            padding-left: 1.2rem;                    
            padding-right: 1.2rem;
            font-size: get-font-size(regular);
            font-weight: get-font-weight(regular);
            vertical-align: middle;

            .m-widget4e-ver__text {
                font-size: get-font-size(regular);
                font-weight: get-font-weight(bolder);                    
            }

            .m-widget4e-ver__title {
                font-size: get-font-size(regular);
                font-weight: get-font-weight(boldest);                    
            } 

            .m-widget4e-ver__sub {
                font-size: get-font-size(regular, '-');
                font-weight: get-font-weight(regular);
            } 
        }

        .m-widget4e-ver__ext {
            display: inline-block; 
            position: relative;
            z-index: 2;
            line-height: 1.6rem;

            .m-widget4e-ver__number {
                font-size: 3rem;
                font-weight: get-font-weight(boldest); 
            }

            .m-widget4e-ver__variation {
                font-size: get-font-size(regular, '++++');
                font-weight: get-font-weight(boldest); 
            }

            .m-widget4e-ver__icon {
                > i {
                    font-size: get-font-size(icon, '++');
                }

                &.m-widget4e-ver__icon--2x {
                    > i {
                        font-size: 2.2rem;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }                        
        }

        &.m-widget4e-ver--progress {
            .m-widget4e-ver__info {
                width: 50%;
            }

            // Progress
            .m-widget4e-ver__progress {     
                display: table-cell; 
                vertical-align: middle;     
                padding-left: 2rem;
                padding-right: 2rem;
                width: 50%;

                .m-widget4e-ver__progress-wrapper {
                    // Stats
                    .m-widget17__progress-number {
                        font-size: get-font-size(regular, '+');
                        font-weight: get-font-weight(boldest);                      
                    }
                    
                    // Text
                    .m-widget17__progress-label {
                        font-size: get-font-size(regular, '-');
                        font-weight: get-font-weight(regular);
                        float: right;
                        margin-top: 0.3rem;
                    }

                    // Progress
                    .progress {
                        display: block;
                        margin-top: 0.8rem;
                        height: 0.5rem;
                    }    
                }                
            }       

        }  

        .m-widget4e-ver__chart {
            position: relative;
        }

        &.m-widget4e-ver--chart-bottom {
            .m-widget4e-ver__chart {
                position: absolute;
                width: 100%;
                bottom: 0;
                opacity: 0.5;

                .m-portlet--rounded & {
                    canvas {
                        @include border-bottom-left-radius(array-get($m--border-radius, general));
                        @include border-bottom-right-radius(array-get($m--border-radius, general));
                    }                
                }
            }
        }
/******/
        .m-widget14__chart {
            position: relative;
            margin-top: 0.5rem;

            .m-widget14__stat {
                font-size: 2.4rem;
                font-weight: get-font-weight(boldest);
                @include vertical-horizontal-center();
            }           
        }
/******/

    } 

}

//== Component Skin
@mixin m-build-component--widget4e-ver-skin() {
    $skin: get-default-skin();    

    .m-widget4e-ver__item { 

        .m-widget4e-ver__number {
            color: get-color($skin, regular);
        }

        .m-widget4e-ver__title {
            color: get-color($skin, regular);       
        } 

        .m-widget4e-ver__sub {
            color: get-color($skin, regular, '--');       
        } 

        .m-widget4e-ver__text {
            color: get-color($skin, regular, '---');
        }

        .m-widget4e-ver__icon {
            > i {
                color: get-color($skin, icon, '+');
            }

            &:hover {
                > i {
                    color: get-brand-color();
                }
            }
        }

        border-bottom: 0.07rem dashed get-color($skin, panel);
         
        &.m-widget4e-ver__item--last,
        &:last-child {
            border-bottom: 0;
        }

    }
}

//== Build Component Base
@include m-build-component--widget4e-ver-base();

//== Build Component - Light Skin
@include m-build-component--widget4e-ver-skin();
