//
//** Spinner 4
//
//

//== Mixins
@mixin m-build-component--loader-base($config) {
	// Spinner markup
	.m-loader {
		position: relative;
		
		&:before {
			content: '';
			box-sizing: border-box;
		  	position: absolute;
		  	top: 50%;
		  	left: 50%;
		  	border-top: 2px solid #07d;
  			border-right: 2px solid transparent;

		  	@include border-radius(50%);
		  	@include animation(m-loader-rotate 0.6s linear infinite);
		}	

		// default size
		@include m-build-component--loader-customize-size( array-get($config, default, size), array-get($config, default, width) );

		&.m-loader--lg {
			@include m-build-component--loader-customize-size( array-get($config, lg, size), array-get($config, lg, width) );
		}

		&.m-loader--sm {
			@include m-build-component--loader-customize-size( array-get($config, sm, size), array-get($config, sm, width) );
		}

		&.m-loader--right {
			&:before {
				left: auto;
				right: (array-get($config, default, size)*0.5);
			}

			&.m-loader--lg {
				&:before {
					right: (array-get($config, lg, size)*0.5);
				}
			}

			&.m-loader--sm {
				&:before {
					right: (array-get($config, sm, size)*0.5);
				}
			}	

			&.btn {
				padding-right: array-get($m--buttons, layout, default, default, self, padding, x) + (1.5 * array-get($config, default, size));
				
				&.m-loader--lg {
					padding-right: array-get($m--buttons, layout, default, lg, self, padding, x) + (1.5 * array-get($config, lg, size));
				}

				&.m-loader--sm {
					padding-right: array-get($m--buttons, layout, default, sm, self, padding, x) + (1.5 * array-get($config, sm, size));
				}	
			}	
		}

		&.m-loader--left {
			&:before {
				left: (array-get($config, default, size));
			}

			&.m-loader--lg {
				&:before {
					left: (array-get($config, lg, size));
				}
			}

			&.m-loader--sm {
				&:before {
					left: (array-get($config, sm, size));
				}
			}	

			&.btn {
				padding-left: array-get($m--buttons, layout, default, default, self, padding, x) + (1.5 * array-get($config, default, size));
				
				&.m-loader--lg {
					padding-left: array-get($m--buttons, layout, default, lg, self, padding, x) + (1.5 * array-get($config, lg, size));
				}

				&.m-loader--sm {
					padding-left: array-get($m--buttons, layout, default, sm, self, padding, x) + (1.5 * array-get($config, sm, size));
				}	
			}		
		}
	}

	// Spinner animation
	@include keyframes(m-loader-rotate) {
		to {
			transform: rotate(360deg);
		}
	}
}

@mixin m-build-component--loader-skin($skin) {
	@include component-skin(m-loader, $skin) {
		&:before {
		  	border-top-color: get-color($skin, panel, '++');	  	
		}	
	}
}

@mixin m-build-component--loader-states() {
    @each $name, $color in $m--state-colors {
        .m-loader.m-loader--#{$name} {
            &:before {
            	border-top-color: array-get($color, base);
            }
        }
    }
}

@mixin m-build-component--loader-customize-size($size, $spinner-width) {
	&:before {
		width: $size;
	    height: $size;
		margin-top: -($size * 0.5);
		margin-left: -($size * 0.5);
		border-top-width: $spinner-width;
		border-right-width: $spinner-width;
	}
}

@mixin m-build-component--loader-customize-skin($spinner-color) {
	&:before {
		border-top-color: $spinner-color;	  	
	}
}

//== Build

@include m-build-component--loader-base( array-get($m--spinners, loader) );

@include m-build-component--loader-skin(light);

@include m-build-component--loader-skin(dark);

@include m-build-component--loader-states();