/* Generated by Glyphter (http://www.glyphter.com) on  Wed Feb 27 2019*/
@font-face {
    font-family: 'iconemma';
    src: url('../fonts/iconemma.eot');
    src: url('../fonts/iconemma.eot?#iefix') format('embedded-opentype'),
         url('../fonts/iconemma.woff') format('woff'),
         url('../fonts/iconemma.ttf') format('truetype'),
         url('../fonts/iconemma.svg#iconemma') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='iconemma-']:before{
	display: inline-block;
   font-family: 'iconemma';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.iconemma-media-summary:before{content:'\0041';}
.iconemma-total-summary:before{content:'\0042';}