//
//** Tooltip Customization
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--bootstrap-popover-base() {
    .m-popover.popover {

        .popover-header {
            font-weight: get-font-weight(bolder);
        }

        .popover-body {
            font-weight: get-font-weight(bold);
        }

        &#{&}#{&}#{&} {
            @include border-radius(array-get($m--border-radius, general));
        }
    }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--bootstrap-popover-base();
