//== Component Base
@mixin m-build-component--emmatable-base() {
  .emma-table {
    table {
      width: 100%;
      table-layout: auto;
    }

    .emma-table--responsive {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .emma-table-actions {
      display: flex;
      margin-bottom: 15px;

      > * {
        vertical-align: top;
      }

      & + .m--align-right-desktop {
        margin-bottom: 15px;

        .m--btn-group-action.has-table-actions {
          & > .btn {
            margin-bottom: 0;
          }
        }
      }
    }

    /* Menu desplegable */

    .row__actions {
      display: inline-block;
      padding-right: 10px;

      .row__actions--menu {
        position: absolute;
        top: calc(50% - 19px);
        left: 3rem;
        z-index: 1000;
        white-space: nowrap;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        li {
          padding: 0;
          display: inline-block;

          div {
            padding: 0.6rem 1.2rem;
            cursor: pointer;
            font-size: 1rem;

            i.la {
              vertical-align: middle;
              top: -0.08rem;
              position: relative;
            }
          }
        }
      }

      .row__actions--button {
        border-radius: 100%;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.5rem;
        text-align: center;
        padding: 0 !important;
      }
    }

    thead {
      position: relative;
      z-index: 10;
      @include transition(array-get($m-config-header, header, self, transition, general));
      th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.column-type--boolean {
          text-align: center;
        }

        &.column-type--number,
        &.column-type--percent,
        &.column-type--currency,
        &.column-type--listLength {
          text-align: right;
        }
      }

      td {
        &.column-type--boolean {
          text-align: center;
        }

        &.column-type--number,
        &.column-type--percent,
        &.column-type--currency,
        &.column-type--listLength {
          text-align: right;
        }
      }

      th,
      td {
        padding: 16px 20px 16px 16px;
        font-weight: get-font-weight(boldest);
        position: relative;

        &.sortable {
          cursor: pointer;

          .column-sortable-icon {
            position: absolute;
            padding-left: 3px;
          }
        }

        &.column--group {
          text-decoration: underline;
        }

        &.column-type--actions,
        &.column-type--calc {
          width: 3rem;
          max-width: 3rem;
        }
      }
    }

    tbody,
    tfoot {
      tr {
        td,
        th {
          padding: 11px 20px 11px 16px;
        }

        .column-type--boolean {
          text-align: center;
        }

        .column-type--date {
          white-space: nowrap;
        }

        .column-type--default,
        .column-type--string,
        .column-type--html,
        .column-type--actionIconList,
        .column-type--iconList,
        .column-type--url,
        .column-type--action,
        .column-type--clipboard {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .column-type--url {
          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: auto;
          }
        }

        .column-type--action {
          span.m-link {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: auto;
          }
        }

        .column-type--actionIconList,
        .column-type--iconList {
          > i,
          > span {
            vertical-align: middle;
          }

          > i {
            margin-right: 3px;

            &:last-of-type {
              margin-right: 8px;
            }
          }
        }

        .column-type--params {
          pre {
            white-space: pre-wrap;
            word-wrap: break-word; /* Internet Explorer 5.5+ */
            margin-bottom: 0;
          }
        }

        .column-type--number,
        .column-type--percent,
        .column-type--ratio,
        .column-type--currency,
        .column-type--listLength {
          text-align: right;
        }

        .column-type--actions,
        .column-type--calc {
          position: relative;
          padding: 11px 6px 11px 16px;
        }
      }
    }

    .row--summary {
      td,
      th {
        white-space: nowrap;
      }
    }

    .emma-table--footer {
      margin-top: 10px;

      .emma-table--pagination,
      .emma-table--rpp-count {
        display: flex;
        align-items: center;
      }

      .emma-table--rpp-count {
        justify-content: flex-end;
      }

      .emma-table--rpp {
        display: inline-block;
        padding-right: 10px;
      }
    }

    .emma-pagination {
      list-style: none;
      text-align: left;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        padding: 0 10px;
        text-decoration: none;

        > i.la {
          font-size: 1rem;
        }

        &.page-nr--current {
          font-weight: get-font-weight(boldest);
        }

        &.disabled {
          pointer-events: none;
        }

        &.page-nr--separator {
          cursor: default;
        }
      }
    }

    .emma-table--no-result {
      .emma-table--no-result__text {
        padding: 1rem 1.2rem;
        font-size: 1.1rem;
        border-top-width: 35px;
        display: flex;
        justify-content: center;

        > i {
          font-size: 1.5rem;
          vertical-align: text-top;
        }

        > span {
          padding-left: 10px;
        }
      }
    }
  }
  .m-header--fixed.m-header--minimize-on .emma-table thead {
    transition: all 0.2s ease;
  }
}

@mixin m-build-component--emmatable-skin($skin) {
  //== Base color
  $base_color: #f4f3f8;

  //== Table
  $base_bg: #fff;

  //== Row colors
  $row-odd: lighten($base-color, 2%);
  $row-hover: lighten($base-color, 1%);
  $row-active: lighten($base-color, 1%);
  $row-group: darken($base-color, 4%);
  $row-group-hover: darken($base-color, 6%);

  .emma-table {
    .emma-table--responsive {
      border: 1px solid $base_color;
    }

    .row__actions {
      .row__actions--menu {
        background-color: #fff;

        @include shadow(get-shadow($skin));
        @include border-radius(array-get($m--border-radius, dropdown));

        ul {
          li:hover {
            div {
              background: get-color($skin, panel, '-');
              color: get-color($skin, link);
            }
          }
        }
      }
    }

    thead {
      th,
      td {
        background-color: $base_color;
      }
    }

    tbody {
      tr {
        /* Class .row--even available */

        &.row--odd {
          background-color: $row-odd;
        }

        &.row--group {
          background-color: $row-group;
        }
      }

      tr:hover {
        &.row--even {
          background-color: $row-hover;
        }

        &.row--odd {
          background-color: $row-hover;
        }

        &.row--group {
          background-color: $row-group-hover;
        }
      }
    }

    .row--summary,
    .row--summary td,
    .row--summary th {
      background-color: get-color($skin, regular);
      color: get-color($skin, box);
    }
  }

  .emma-table--footer {
    .emma-pagination {
      li {
        &:not(.page-nr--separator):hover {
          color: #00a263;
        }

        &.page-nr--current {
          font-weight: get-font-weight(boldest);
        }

        &.disabled,
        &.disabled:hover {
          color: array-get($m--forms, input, skins, $skin, solid, color, disabled);
        }
      }
    }
  }

  .emma-table--no-result {
    .emma-table--no-result__text {
      border: 1px solid $base_color;
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmatable-base();

//== Component Skin
@include m-build-component--emmatable-skin(light);
