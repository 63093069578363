//
//** Override The Base Framework Config For This Demo
//

//== Example(reduce font size from 14px to 13px globally for desktop mode only)

//== The body element font settings
/**
$m--root-font: (
    size: (
        desktop: 13px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 300, 
    color: (
        light: #333333, 
        dark: #efefef
    )
);
*/

//
//** Override The Base Framework Config For This Demo
//

//== The body element font settings
$m--root-font: (
    size: (
        desktop: 14px,
        tablet: 13px,
        mobile: 13px
    ), 
    weight: 400, 
    color: (
        light: #14213D, 
        dark: #ffffff
    )
);

//== Font families
$m--font-families: (
    regular: 'Work Sans',  
    heading: 'Work Sans'
);

//== Brand Color
$m--brand-color: #f3a229;
$m--brand-inverse-color: #14213d;

$m--state-colors: (
    // SKUDO states
    brand: (
        base: $m--brand-color,
        inverse: $m--brand-inverse-color
    ),
    metal: (
        base: #e5e5e5,
        inverse: $m--brand-inverse-color
    ),  
    light: (
        base: #ffffff,
        inverse: $m--brand-inverse-color 
    ),
    skudodarkgrey: (
        base: #9198a7,
        inverse: $m--brand-inverse-color 
    ),
    skudoorange: (
        base: #f3a229,
        inverse: #14213d 
    ),
    skudoorangewhite: (
        base: #f3a229,
        inverse: #ffffff 
    ),
    skudodarkblue: (
        base: #14213d,
        inverse: #f3a229 
    ),
    skudodarkbluewhite: (
        base: #14213d,
        inverse: #ffffff 
    ),
    skudolightkblue: (
        base: #5F8FF7,
        inverse: #14213d 
    ),
    altpink: (
        base: #e0287b,
        inverse: #ffffff
    ),
    altblue: (
        base: #2c6bf4,
        inverse: #ffffff
    ),
    altgreen: (
        base: #a2d948,
        inverse: #14213d
    ),
    altviolet: (
        base: #713aed,
        inverse: #ffffff
    ),
    // metronic states
    accent: (
        base: #00c5dc,
        inverse: #ffffff 
    ),
    focus: (
        base: #9816f4,
        inverse: #ffffff 
    ),
    // bootstrap states
    primary: (
        base: #5867dd,
        inverse: #ffffff
    ),
    success: (
        base: #27863C,
        inverse: #ffffff
    ),
    info: (
        base: #14213d,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #14213d
    ),
    danger: (
        base: #C73232,
        inverse: #ffffff
    ),


)!default;

//== General typography font colors
$m--typography-colors: (
    // light skin
    light: (
        // regular text color
        regular: (
            lighten5: lighten(#14213d, 30%), // access to this color value calling get-color function as get-color(light, regular, -----) in SASS code
            lighten4: lighten(#14213d, 25%), // access to this color value calling get-color function as get-color(light, regular, ----) in SASS code
            lighten3: lighten(#14213d, 20%), // access to this color value calling get-color function as get-color(light, regular, ---) in SASS code
            lighten2: lighten(#14213d, 15%), // access to this color value calling get-color function as get-color(light, regular, --) in SASS code
            lighten1: lighten(#14213d, 10%), // access to this color value calling get-color function as get-color(light, regular, -) in SASS code
            base:     #14213d,               // access to this color value calling get-color function as get-color(light, regular) in SASS code
            darken1:  darken(#14213d, 10%),  // access to this color value calling get-color function as get-color(light, regular, +) in SASS code
            darken2:  darken(#14213d, 15%),  // access to this color value calling get-color function as get-color(light, regular, ++) in SASS code
            darken3:  darken(#14213d, 20%),  // access to this color value calling get-color function as get-color(light, regular, +++) in SASS code
            darken4:  darken(#14213d, 25%),  // access to this color value calling get-color function as get-color(light, regular, ++++) in SASS code
            darken5:  darken(#14213d, 30%)   // access to this color value calling get-color function as get-color(light, regular, +++++) in SASS code 
        ),

        // link color
        link: (
            lighten5: lighten(#f3a229, 30%), // access to this color value calling get-color function as get-color(light, link, -----) in SASS code
            lighten4: lighten(#f3a229, 25%), // access to this color value calling get-color function as get-color(light, link, ----) in SASS code
            lighten3: lighten(#f3a229, 20%), // access to this color value calling get-color function as get-color(light, link, ---) in SASS code
            lighten2: lighten(#f3a229, 15%), // access to this color value calling get-color function as get-color(light, link, --) in SASS code
            lighten1: lighten(#f3a229, 10%), // access to this color value calling get-color function as get-color(light, link, -) in SASS code
            base:     #f3a229, 
            darken1:  darken(#f3a229, 10%),  // access to this color value calling get-color function as get-color(light, link, +++++) in SASS code
            darken2:  darken(#f3a229, 15%),  // access to this color value calling get-color function as get-color(light, link, ++++) in SASS code
            darken3:  darken(#f3a229, 20%),  // access to this color value calling get-color function as get-color(light, link, +++) in SASS code
            darken4:  darken(#f3a229, 25%),  // access to this color value calling get-color function as get-color(light, link, ++) in SASS code
            darken5:  darken(#f3a229, 30%)   // access to this color value calling get-color function as get-color(light, link, +) in SASS code
        ),

        // disabled color
        disabled: (
            lighten5: lighten(#E5E5E5, 7%), // access to this color value calling get-color function as get-color(light, disabled, -----) in SASS code
            lighten4: lighten(#E5E5E5, 6%), // access to this color value calling get-color function as get-color(light, disabled, ----) in SASS code
            lighten3: lighten(#E5E5E5, 5%), // access to this color value calling get-color function as get-color(light, disabled, ---) in SASS code
            lighten2: lighten(#E5E5E5, 4%), // access to this color value calling get-color function as get-color(light, disabled, --) in SASS code
            lighten1: lighten(#E5E5E5, 3%), // access to this color value calling get-color function as get-color(light, disabled, -) in SASS code
            base:     #E5E5E5, 
            darken1:  desaturate(darken(#E5E5E5, 5), 10),  // access to this color value calling get-color function as get-color(light, disabled, +++++) in SASS code
            darken2:  desaturate(darken(#E5E5E5, 10), 10),  // access to this color value calling get-color function as get-color(light, disabled, ++++) in SASS code
            darken3:  desaturate(darken(#E5E5E5, 15), 10),  // access to this color value calling get-color function as get-color(light, disabled, +++) in SASS code
            darken4:  desaturate(darken(#E5E5E5, 20), 10),  // access to this color value calling get-color function as get-color(light, disabled, ++) in SASS code
            darken5:  desaturate(darken(#E5E5E5, 25), 10)   // access to this color value calling get-color function as get-color(light, disabled, +) in SASS code
        ),

        // icon color
        icon: (
            lighten5: lighten(#9198A7, 25%),
            lighten4: lighten(#9198A7, 20%),
            lighten3: lighten(#9198A7, 15%),
            lighten2: lighten(#9198A7, 10%),
            lighten1: lighten(#9198A7, 5%),
            base:     #9198A7, 
            darken1:  darken(#9198A7, 5%),
            darken2:  darken(#9198A7, 10%),
            darken3:  darken(#9198A7, 15%),
            darken4:  darken(#9198A7, 20%),
            darken5:  darken(#9198A7, 25%)
        ),

        // muted text color
        muted: (
            lighten5: lighten(#9198A7, 30%),
            lighten4: lighten(#9198A7, 25%),
            lighten3: lighten(#9198A7, 20%),
            lighten2: lighten(#9198A7, 15%),
            lighten1: lighten(#9198A7, 10%),
            base:     #9198A7, 
            darken1:  darken(#9198A7, 10%),
            darken2:  darken(#9198A7, 15%),
            darken3:  darken(#9198A7, 20%),
            darken4:  darken(#9198A7, 25%),
            darken5:  darken(#9198A7, 30%)
        ), 

        // panel(border colors)
        panel: (
            lighten5: lighten(#E5E5E5, 7%),
            lighten4: lighten(#E5E5E5, 6%),
            lighten3: lighten(#E5E5E5, 5%),
            lighten2: lighten(#E5E5E5, 4%),
            lighten1: lighten(#E5E5E5, 3%),
            base:     #E5E5E5, 
            darken1:  darken(#E5E5E5, 3%),
            darken2:  darken(#E5E5E5, 4%),
            darken3:  darken(#E5E5E5, 15%),
            darken4:  darken(#E5E5E5, 20%),
            darken5:  darken(#E5E5E5, 25%),
        ),

        // box(background colors)
        box: (
            lighten5: lighten(#ffffff, 6%),
            lighten4: lighten(#ffffff, 5%),
            lighten3: lighten(#ffffff, 4%),
            lighten2: lighten(#ffffff, 3%),
            lighten1: lighten(#ffffff, 2%),
            base:     #ffffff, 
            darken1:  darken(#ffffff, 2%),
            darken2:  darken(#ffffff, 3%),
            darken3:  darken(#ffffff, 4%),
            darken4:  darken(#ffffff, 5%),
            darken5:  darken(#ffffff, 6%),
        )
    ),

    // dark skin
    dark: (
        // regular text color
        regular: (
            lighten5: lighten(#ffffff, 25%), // access to this color value calling get-color function as get-color(dark, regular, +++++) in SASS code
            lighten4: lighten(#ffffff, 20%), // access to this color value calling get-color function as get-color(dark, regular, ++++) in SASS code
            lighten3: lighten(#ffffff, 15%), // access to this color value calling get-color function as get-color(dark, regular, +++) in SASS code
            lighten2: lighten(#ffffff, 10%), // access to this color value calling get-color function as get-color(dark, regular, ++) in SASS code
            lighten1: lighten(#ffffff, 5%), // access to this color value calling get-color function as get-color(dark, regular, +) in SASS code
            base:     #ffffff,               // access to this color value calling get-color function as get-color(dark, regular) in SASS code
            darken1:  darken(#ffffff, 5%),  // access to this color value calling get-color function as get-color(dark, regular, -) in SASS code
            darken2:  darken(#ffffff, 10%),  // access to this color value calling get-color function as get-color(dark, regular, --) in SASS code
            darken3:  darken(#ffffff, 15%),  // access to this color value calling get-color function as get-color(dark, regular, ---) in SASS code
            darken4:  darken(#ffffff, 20%),  // access to this color value calling get-color function as get-color(dark, regular, ----) in SASS code
            darken5:  darken(#ffffff, 25%)   // access to this color value calling get-color function as get-color(dark, regular, -----) in SASS code 
        ),

        // link color
        link: (
            lighten5: lighten(#f3a229, 30%), // access to this color value calling get-color function as get-color(dark, link, +++++) in SASS code
            lighten4: lighten(#f3a229, 25%), // access to this color value calling get-color function as get-color(dark, link, ++++) in SASS code
            lighten3: lighten(#f3a229, 20%), // access to this color value calling get-color function as get-color(dark, link, +++) in SASS code
            lighten2: lighten(#f3a229, 15%), // access to this color value calling get-color function as get-color(dark, link, ++) in SASS code
            lighten1: lighten(#f3a229, 10%), // access to this color value calling get-color function as get-color(dark, link, +) in SASS code
            base:     #f3a229,               // access to this color value calling get-color function as get-color(dark, link) in SASS code
            darken1:  darken(#f3a229, 10%),  // access to this color value calling get-color function as get-color(dark, link, -) in SASS code
            darken2:  darken(#f3a229, 15%),  // access to this color value calling get-color function as get-color(dark, link, --) in SASS code
            darken3:  darken(#f3a229, 20%),  // access to this color value calling get-color function as get-color(dark, link, ---) in SASS code
            darken4:  darken(#f3a229, 25%),  // access to this color value calling get-color function as get-color(dark, link, ----) in SASS code
            darken5:  darken(#f3a229, 30%)   // access to this color value calling get-color function as get-color(dark, link, -----) in SASS code 
        ),

        // icon color
        icon: (
            lighten5: lighten(#dfdff1, 25%),
            lighten4: lighten(#dfdff1, 20%),
            lighten3: lighten(#dfdff1, 15%),
            lighten2: lighten(#dfdff1, 10%),
            lighten1: lighten(#dfdff1, 5%),
            base:     #dfdff1, 
            darken1:  darken(#dfdff1, 5%),
            darken2:  darken(#dfdff1, 10%),
            darken3:  darken(#dfdff1, 15%),
            darken4:  darken(#dfdff1, 20%),
            darken5:  darken(#dfdff1, 25%)
        ),

        // muted text color
        muted: (
            lighten5: lighten(#9198A7, 30%),
            lighten4: lighten(#9198A7, 25%),
            lighten3: lighten(#9198A7, 20%),
            lighten2: lighten(#9198A7, 15%),
            lighten1: lighten(#9198A7, 10%),
            base:     #9198A7, 
            darken1:  darken(#9198A7, 10%),
            darken2:  darken(#9198A7, 15%),
            darken3:  darken(#9198A7, 20%),
            darken4:  darken(#9198A7, 25%),
            darken5:  darken(#9198A7, 30%)
        ), 

        // panel(border colors)
        panel: (
            lighten5: lighten(#E5E5E5, 7%),
            lighten4: lighten(#E5E5E5, 6%),
            lighten3: lighten(#E5E5E5, 5%),
            lighten2: lighten(#E5E5E5, 4%),
            lighten1: lighten(#E5E5E5, 3%),
            base:     #E5E5E5, 
            darken1:  darken(#E5E5E5, 3%),
            darken2:  darken(#E5E5E5, 4%),
            darken3:  darken(#E5E5E5, 15%),
            darken4:  darken(#E5E5E5, 20%),
            darken5:  darken(#E5E5E5, 25%),
        ),

        // box(background colors)
        box: (
            lighten5: lighten(#14213d, 6%),
            lighten4: lighten(#14213d, 5%),
            lighten3: lighten(#14213d, 4%),
            lighten2: lighten(#14213d, 3%),
            lighten1: lighten(#14213d, 2%),
            base:     #14213d, 
            darken1:  darken(#14213d, 2%),
            darken2:  darken(#14213d, 3%),
            darken3:  darken(#14213d, 4%),
            darken4:  darken(#14213d, 5%),
            darken5:  darken(#14213d, 6%),
        )
    )
)!default;  