//
//** User Mi Plan
//

//== General

@mixin build-userPlan() {
  $skin: get-default-skin();

  emma-user-plan-overview-v2-page {

    .emma-resume-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: array-get($m--forms, input, skins, $skin, solid, bg, disabled);
      border-color: array-get($m--forms, input, skins, $skin, solid, border, default);
      padding: 2rem;
      @include border-radius(8px);

      .emma-resume-block {
        flex: 1 30%;
        font-size: 1.3rem;

        &.main-info {
          display: flex;
          flex-direction: column;

          h5 {
            font-size: 2rem;
            font-weight: 600;
          }

          button {
            margin-top: auto;
            align-self: flex-start;
          }
        }

        &.complements-info {

          h4 {
            display: block;
            font-size: 1.5rem;
            font-weight: 600;
          }
        }

        &.invoice-info {

          h4 {
            display: block;
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0;
          }

          .emma-resume-subtitle {
            font-size: 1.2rem;
            font-weight: 400;
            font-style: italic;
            margin-bottom: 0.5rem;
          }

          .emma-resume-total {
            font-size: 1.75rem;
            font-weight: 400;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  emma-plan-configurator {

    .emma-plan-generator--standalone {

      .m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title {
        display: none;
      }

      .m-form.m-form--sidebar .m-form--sidebar-content .m-form--sidebar-content_sticky {
        top: 25px;
      }
    }

    .valueSliderPlan.input-group-slider-sibling {
      margin-bottom: 0;
      padding-top: 5px;
      width: 200px;
      white-space: nowrap;
    }

    .payModelPlan {
      font-size: 1rem;
      padding-left: 10px;
    }

    .planTotal {
      font-size: 1.2rem;
      align-items: center;
    }

    .priceRow {
      margin-left: auto;
    }
  }

  emma-plan-configurator-v2 {

    .emma-plan-generator--standalone {

      .m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title {
        display: none;
      }

      .m-form.m-form--sidebar .m-form--sidebar-content .m-form--sidebar-content_sticky {
        top: 25px;
      }
    }

    .valueSliderPlan.input-group-slider-sibling {
      margin-bottom: 0;
      padding-top: 5px;
      width: 200px;
      white-space: nowrap;
    }

    .payModelPlan {
      font-size: 1rem;
      font-weight: get-font-weight(bold);
    }

    .planTotal {
      font-size: 1.2rem;
      align-items: center;
    }

    .priceRow {
      margin-left: auto;
    }

    .m-form__sidebar-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .emma-cards-selector {

      .emma-cards-section {
        font-size: get-font-size(regular, '+++');
        font-weight: get-font-weight(bolder);
      }
      
      .emma-cards-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        .emma-card {
          display: flex;
          flex-direction: column;
          max-width: 180px;
          min-height: 210px;
          border-width: 4px;
          border-style: solid;
          background-color: array-get($m--forms, input, skins, $skin, solid, bg, disabled);
          border-color: array-get($m--forms, input, skins, $skin, solid, border, default);
          padding: 15px;
          @include border-radius(8px);
          
          &.active {
            border-color: get-state-color(altgreen, base);
          }

          h4 {
            font-size: get-font-size(regular, '++');
            min-height: 40px;
          }

          .emma-card-cost {
            font-size: get-font-size(regular, '+');

            .emma-card-amount {
              font-size: get-font-size(regular, '+');
            }

            .recurrent {
              font-weight: get-font-weight(bold);
            }
          }

          .emma-card-base {
            font-size: get-font-size(regular);
          }

          .emma-card-description {
            font-size: get-font-size(icon, '-----');
            font-weight: get-font-weight(bold);
            align-self: flex-end;
            font-style: italic;
            padding-top: 1rem;
            margin-top: auto;
          }

          .emma-card-action {
            justify-self: flex-end;
          }
        }
      }
    }
  }

  @include tablet-and-mobile {

    .emma-plan-generator--standalone {

      .m-form.m-form--sidebar .m-form--sidebar-content .m-form--sidebar-content_sticky {
        top: auto;
      }
    }
  }
}

//==== Generate Component ====//

//== Component General

  @include build-userPlan();
