 //
//** List Widget 4 Component
//

//== Component Base
@mixin m-build-component--widget4-base() {
    .m-widget4 {

        .m-widget14__chart {
            position: relative;
            margin-top: 0.5rem;

            .m-widget14__stat {
                font-size: 1.8rem;
                text-align: right;
                font-weight: get-font-weight(boldest);
                @include vertical-horizontal-center();

                > span {
                    display: block;
                    font-size: 1.5rem;
                    line-height: 1rem;
                }
            }
        }

        .m-widget14__legend {

            .m-widget14__legend-extraLabel {
                margin-bottom: 0.9rem;

                &:last-child {
                    margin-bottom: 0;
               }
            }

            .m-widget14__legend-icon {
                width: 2rem;
                display: inline-block;
                border-radius: 1.1rem;
                margin-bottom: 0.12rem;
                margin-right: 0.8rem;
                text-align: center;
            }
        }

    }

    .m-widget4--extraDescription {
        font-size: 1rem;
        font-weight: get-font-weight(bolder);
        margin-top: -8px;
    }

    .m-widget4--extraInfo {
        font-size: 1rem;
        font-weight: get-font-weight(bold);
    }

    @include tablet-and-mobile {

        .m-widget4--extraDescription {
            font-size: .9rem;
        }

        .m-widget4--extraInfo {
            font-size: .9rem;
        }
    }
}

//== Component Skin
@mixin m-build-component--widget4-skin() {
    $skin: get-default-skin();

    .m-widget4 {

        .m-widget14__chart {
            .m-widget14__stat.invertedHighlight {
                color: get-brand-color(); 
                
                > span {
                    color: get-color($skin, muted, '--'); 
                }
            } 
        }

        .m-widget14__legend {

            .m-widget14__legend-icon {
                color: get-color($skin, regular, '----');
            }
        }
    }
}


//== Build Component Base
@include m-build-component--widget4-base();

//== Build Component - Light Skin
@include m-build-component--widget4-skin();
