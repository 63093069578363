//
//** Input Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--input-base() {
  // base input
  .form-control {
    font-family: #{array-get($m--forms, input, layout, self, font-family)};

    &.m-input--pill {
      @include border-radius(array-get($m--forms, input, layout, self, size, default, pill-radius));

      &.form-control-lg {
        @include border-radius(array-get($m--forms, input, layout, self, size, lg, pill-radius));
      }

      &.form-control-sm {
        @include border-radius(array-get($m--forms, input, layout, self, size, sm, pill-radius));
      }
    }

    &.m-input--square {
      @include border-radius(0);
    }

    &.m-input--fixed {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, default, fixed);
    }

    &.m-input--fixed-large {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, lg, fixed);
    }

    &.m-input--fixed-small {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, sm, fixed);
    }
  }

  // input group
  .input-group {
    //== Group text
    .input-group-text {
      > i {
        line-height: 0;
        vertical-align: middle;
        @include icons-size(array-get($m--forms, input, layout, icon, default, font-size));
      }

      > .m-checkbox,
      > .m-radio {
        margin: 0;
        padding: 0;
        height: 0.9rem;
      }
    }

    .btn {
      > i {
        line-height: 0;
      }

      &.m-btn--icon-only {
        height: auto;
      }
    }

    // large icon sizing
    &.input-group-lg {
      .input-group-text {
        @include icons-size(array-get($m--forms, input, layout, icon, lg, font-size));
      }
    }

    // small icon sizing
    &.input-group-sm {
      .input-group-text {
        @include icons-size(array-get($m--forms, input, layout, icon, sm, font-size));
      }
    }

    // fixed width input groups
    &.m-input-group--fixed {
      width: array-get($m--forms, input, layout, self, size, default, fixed);
    }

    &.m-input-group--fixed-large {
      width: array-get($m--forms, input, layout, self, size, lg, fixed);
    }

    &.m-input-group--fixed-small {
      width: array-get($m--forms, input, layout, self, size, sm, fixed);
    }

    // squre style
    &.m-input-group--square {
      @include border-radius(0);

      > .form-control:first-child,
      > div:first-child > span,
      > div:first-child > .btn,
      emma-input:first-child .form-control {
        @include border-radius(0);
      }

      > .form-control:last-child,
      > div:last-child > span,
      > div:last-child > .btn,
      emma-input:last-child .form-control {
        @include border-radius(0);
      }
    }

    // pill style
    &.m-input-group--pill {
      @include border-radius(array-get($m--forms, input, layout, self, size, default, pill-radius));

      > .form-control:first-child,
      > div:first-child > span,
      > div:first-child > .btn,
      emma-input:first-child .form-control {
        @include border-top-left-radius(
          array-get($m--forms, input, layout, self, size, default, pill-radius)
        );
        @include border-bottom-left-radius(
          array-get($m--forms, input, layout, self, size, default, pill-radius)
        );
      }

      > .form-control:last-child,
      > div:last-child > span,
      > div:last-child > .btn,
      emma-input:last-child .form-control {
        @include border-top-right-radius(
          array-get($m--forms, input, layout, self, size, default, pill-radius)
        );
        @include border-bottom-right-radius(
          array-get($m--forms, input, layout, self, size, default, pill-radius)
        );
      }
    }

    .input-group-switch {
      padding-right: 10px;
      padding-top: 3px;
    }

    .input-group-slider {
      flex: 1 1 auto;
      // Add width 1% and flex-basis auto to ensure that button will not wrap out
      // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
      width: 1%;
      align-self: center;
    }

    .input-group-slider-sibling {
      width: auto;
      min-width: 105px;
      align-self: center;

      > span {
        flex: 1 1 auto;
        align-self: center;
        font-variant-numeric: tabular-nums;
        font-size: 1rem;
      }
    }

    .input-group-slider-value {
      width: auto;
      min-width: 105px;
      align-self: center;

      > label {
        display: flex;
        flex: 1 1 auto;
        gap: .5rem;
        align-self: center;
        font-variant-numeric: tabular-nums;

        &.reverse-tag {
          flex-direction: row-reverse;
        }
      }
    }

    .testABVersionPercent {
      display: block;
      min-width: 50px;
      text-align: right;
      margin-left: 15px;
  
      > label {
        margin-bottom: 0;
        padding-top: 5px;
      }
    }

    &.input-group-table {
      .input-group-fluid {
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        flex-wrap: nowrap;

        > .form-control,
        > emma-input,
        .emma-input {
          width: 100%;
        }

        > .form-control {
          &.filter-table-input {
            max-width: 300px;
          }
        }

        &.filter-table-group {
          max-width: 300px;
          margin-left: auto;
        }

        > emma-input {
          .emma-input {
            > .form-control {
              &.filter-table-input {
                max-width: 300px;
                margin-left: auto;
              }
            }
          }
        }

        & + .m--btn-group-action.has-table-actions {
          padding-left: 15px;
        }
      }
    }

    emma-input,
    .emma-input {
      display: flex;
      flex: 1 1 auto;
      // Add width 1% and flex-basis auto to ensure that button will not wrap out
      // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
      width: 1%;

      &:not(:last-child) {
        .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }
      }

      &:not(:first-child) {
        .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }

    emma-select {
      flex: 1 1 auto;
      // Add width 1% and flex-basis auto to ensure that button will not wrap out
      // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
      width: 1%;
    }
  }

  .split-group {
    display: flex;
    gap: 10px;

    > .fluid-element,
    > * {
      flex: 1 1 auto;
      // Add width 1% and flex-basis auto to ensure that button will not wrap out
      // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
      width: 1%;
      min-width: 0;
    }

    > .stable-element {
      flex: 0 1 auto;
    }
  }

  //== Subinput

  .subinput {
    padding-left: 15px;
    border-left-width: 3px;
    border-left-style: solid;
  }

  // input icon
  .m-input-icon {
    position: relative;
    padding: 0;
    width: 100%;

    // icon input sizing
    > .m-input-icon__icon {
      position: absolute;
      height: 100%;
      display: inline-block;
      text-align: center;
      top: 0;
      width: array-get($m--forms, input, layout, icon, default, width);

      &.m-input-icon__icon--left {
        left: 0;
      }

      &.m-input-icon__icon--right {
        right: 0;
      }

      > span {
        display: table;
        height: 100%;
        width: 100%;

        > i {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          line-height: 0;
        }
      }

      // set icon sizes
      @include icons-size(array-get($m--forms, input, layout, icon, default, font-size));
    }

    .form-control.form-control-lg + .m-input-icon__icon {
      width: array-get($m--forms, input, layout, icon, lg, width);

      // set icon sizes
      @include icons-size(array-get($m--forms, input, layout, icon, lg, font-size));
    }

    .form-control.form-control-sm ~ .m-input-icon__icon {
      width: array-get($m--forms, input, layout, icon, sm, width);

      // set icon sizes
      @include icons-size(array-get($m--forms, input, layout, icon, sm, font-size));
    }

    // icon input alignments
    &.m-input-icon--left {
      .form-control {
        padding-left: (array-get($m--forms, input, layout, icon, default, width) - 0.4rem);

        &.form-control-lg {
          padding-left: (array-get($m--forms, input, layout, icon, lg, width) - 0.6rem);
        }

        &.form-control-sm {
          padding-left: (array-get($m--forms, input, layout, icon, sm, width) - 0.2rem);
        }
      }
    }

    &.m-input-icon--right {
      .form-control {
        padding-right: (array-get($m--forms, input, layout, icon, default, width) - 0.4rem);

        &.form-control-lg {
          padding-right: (array-get($m--forms, input, layout, icon, lg, width) - 0.6rem);
        }

        &.form-control-sm {
          padding-right: (array-get($m--forms, input, layout, icon, sm, width) - 0.2rem);
        }
      }
    }

    // fixed width icon inputs
    &.m-input-icon--fixed {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, default, fixed);
    }

    &.m-input-icon--fixed-large {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, lg, fixed);
    }

    &.m-input-icon--fixed-small {
      display: inline-block;
      width: array-get($m--forms, input, layout, self, size, sm, fixed);
    }

    &.m-input-text {
      display: flex;

      .m-input-text__text {
        align-self: center;
        font-size: 1rem;
        padding: 0 0 0 5px;

        &:last-child {
          padding: 0 5px 0 0;
        }
      }

      .form-control {
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
      }

      emma-input,
      .emma-input {
        display: flex;
        flex: 1 1 auto;
      }

      &.disabled {
        .m-input-text__text {
          opacity: 0.65;
        }
      }
    }
  }

  //copy to clipboard

  emma-input .emma-input.emma-input__copyToClipboard {
    .form-control {
      padding-right: 30px;
    }

    .emma-copyToClipboard {
      position: absolute;
      top: calc(50% - 13px);
      right: 5px;
    }
  }
}

//== Component Skin
@mixin m-build-component--input-skin($config) {
  $skin: get-default-skin();

  //== Base input
  .form-control {
    // default input style

    &[readonly],
    & {
      border-color: array-get($config, input, skins, $skin, default, border, default);
      color: array-get($config, input, skins, $skin, default, color, default);
      @include input-placeholder(array-get($config, input, skins, $skin, default, color, placeholder));
    }

    &[readonly] {
      background-color: transparent;
    }

    &.focus:not([readonly]),
    &:focus:not([readonly]) {
      border-color: array-get($config, input, skins, $skin, default, border, focus);
      color: array-get($config, input, skins, $skin, default, color, focus);
      @include shadow(none);
    }

    &.active {
      @include shadow(none);
    }

    &[disabled] {
      border-color: array-get($config, input, skins, $skin, default, border, disabled);
      color: array-get($config, input, skins, $skin, default, color, disabled);
      background-color: array-get($config, input, skins, $skin, default, bg, disabled);
      opacity: $btn-disabled-opacity;
      cursor: not-allowed;
    }

    // solid input style
    &.m-input--solid,
    &.emma-input__primary {
      background-color: array-get($config, input, skins, $skin, solid, bg, default);
      border-color: array-get($config, input, skins, $skin, solid, border, default);
      color: array-get($config, input, skins, $skin, solid, color, default);
      @include input-placeholder(array-get($config, input, skins, $skin, solid, color, placeholder));

      &:focus:not([readonly]) {
        background-color: array-get($config, input, skins, $skin, solid, bg, focus);
        border-color: array-get($config, input, skins, $skin, solid, border, focus);
        color: array-get($config, input, skins, $skin, solid, color, focus);
      }

      &[disabled] {
        border-color: array-get($config, input, skins, $skin, solid, border, disabled);
        // color: array-get($config, input, skins, $skin, solid, color, disabled);
        color: array-get($config, input, skins, $skin, solid, color, default);
        background-color: array-get($config, input, skins, $skin, solid, bg, disabled);
      }

      &:focus[readonly],
      &[readonly] {
        border-color: array-get($config, input, skins, $skin, solid, border, default);
        background-color: transparent;
      }

      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {
        border-color: array-get($config, input, skins, $skin, ko, border, default);
        color: array-get($config, input, skins, $skin, ko, bg, default);
      }
    }

    &.m-input--air {
      @include shadow(array-get($config, input, skins, $skin, air, box-shadow, default));

      &:focus {
        @include shadow(array-get($config, input, skins, $skin, air, box-shadow, focus));
      }
    }

    &.m-input--ok {
      background-color: array-get($config, input, skins, $skin, ok, bg, default);
      border-color: array-get($config, input, skins, $skin, ok, border, default);
      color: array-get($config, input, skins, $skin, ok, color, default);
      @include input-placeholder(array-get($config, input, skins, $skin, ok, color, placeholder));
    }

    &.m-input--ko {
      background-color: array-get($config, input, skins, $skin, ko, bg, default);
      border-color: array-get($config, input, skins, $skin, ko, border, default);
      color: array-get($config, input, skins, $skin, ko, color, default);
      @include input-placeholder(array-get($config, input, skins, $skin, ko, color, placeholder));
    }
  }

  //== Input group
  .input-group {
    .input-group-append {
      & + .form-control:not(:focus):not(.focus) {
        border-left: 0;
      }
    }

    &.m-input-group--air {
      @include shadow(array-get($config, input, skins, $skin, air, box-shadow, default));
    }

    &.m-input-group--solid {
      .form-control {
        border-color: array-get($config, input, skins, $skin, default, border, default);
        background-color: lighten(array-get($config, input, skins, $skin, default, border, default), 3%);

        color: array-get($config, input, skins, $skin, solid, color, default);
        @include input-placeholder(array-get($config, input, skins, $skin, solid, color, placeholder));

        &:focus {
          background-color: array-get($config, input, skins, $skin, solid, bg, focus);
          border-color: array-get($config, input, skins, $skin, solid, border, focus);
          color: array-get($config, input, skins, $skin, solid, color, focus);
        }

        &[disabled] {
          border-color: array-get($config, input, skins, $skin, solid, border, disabled);
          color: array-get($config, input, skins, $skin, solid, color, disabled);
          background-color: array-get($config, input, skins, $skin, solid, bg, disabled);
        }
      }
    }

    &.emma-input-color {
      > .input-group-text {
        padding-right: 0;
        border-color: array-get($config, input, skins, $skin, solid, border, default);
        border-right: none;
        @include transition(border-color 0.3s);
      }

      emma-input > .emma-input {
        input.form-control.m-input {
          border-left-color: transparent;
          padding-left: 0;

          /* .ng-invalid.ng-touched {
                        border-color: array-get($config, input, skins, $skin, ko, border, default);
                    }

                    .ng-valid.ng-touched {
                        border-color: array-get($config, input, skins, $skin, ok, border, default);
                    } */
        }
      }

      &:focus-within {
        > .input-group-text {
        border-color: array-get($config, input, skins, $skin, solid, border, focus);
        }
      }
    }

    > .input-group-text {
      border-color: array-get($config, input, skins, $skin, solid, border, default);
      background-color: array-get($config, input, skins, $skin, solid, bg, default);
      color: get-color($skin, regular);

      > i {
        color: get-color($skin, icon, '+++');
      }

      &.input-group-text-transparent {
        border-color: transparent;
        background-color: transparent;
        color: get-color($skin, regular);

        > i {
          color: get-color($skin, icon, '+++');
        }
      }
    }

    > .input-group-color {
      border-top-left-radius: array-get($m--forms, input, layout, self, size, default, border-radius);
      border-bottom-left-radius: array-get($m--forms, input, layout, self, size, default, border-radius);
      width: array-get($m--forms, input, layout, icon, default, width);
    }

    > .input-group-color.nocolor {
      border-top: $btn-border-width dashed lighten(array-get($config, input, skins, $skin, solid, border, default), 3%);
      border-bottom: $btn-border-width dashed
        lighten(array-get($config, input, skins, $skin, solid, border, default), 3%);
      border-left: $btn-border-width dashed lighten(array-get($config, input, skins, $skin, solid, border, default), 3%);
    }

    > .input-group-color:not(.nocolor) {
      border-color: transparent !important;
    }
  }

  emma-input-color.ng-invalid {
    &.ng-touched {
      .input-group.emma-input-color {
        > .input-group-text {
          border-color: array-get($config, input, skins, $skin, ko, border, default);
          border-right-color: transparent;
          color: array-get($config, input, skins, $skin, ko, bg, default);
        }
      }
    }

    .input-group.emma-input-color:focus-within {
      > .input-group-text {
        border-color: array-get($config, input, skins, $skin, ko, border, default);
        border-right-color: transparent;
        color: array-get($config, input, skins, $skin, ko, bg, default);
      }

      emma-input > .emma-input {
        input.form-control.m-input {
          border-color: array-get($config, input, skins, $skin, ko, border, default);
          border-left-color: transparent;
          padding-left: 0;
        }
      }
    }
  }

  emma-input-color.ng-valid {
    /* &.ng-touched {
            .input-group.emma-input-color {
                .input-group-prepend {
                    > .input-group-text {
                        border-color: array-get($config, input, skins, $skin, ok, border, default);
                        border-right-color: transparent;
                    }
                }
            }
        } */

    .input-group.emma-input-color:focus-within {
      > .input-group-text {
        border-color: array-get($config, input, skins, $skin, ok, border, default);
        border-right-color: transparent;
      }

      emma-input > .emma-input {
        input.form-control.m-input {
          border-color: array-get($config, input, skins, $skin, ok, border, default);
          border-left-color: transparent;
          padding-left: 0;
        }
      }
    }
  }

  //== Subinput

  .subinput {
    border-left-color: array-get($config, input, skins, $skin, default, border, disabled);
  }

  //== Custom icon
  .m-input-icon {
    .m-input-icon__icon i {
      color: get-color($skin, icon, '+++');
    }
  }

  //== Custom file
  .custom-file {
    .custom-file-label {
      border-color: array-get($config, input, skins, $skin, default, border, default);
      color: array-get($config, input, skins, $skin, default, color, default);

      &:after {
        cursor: pointer;
        background-color: lighten(array-get($config, input, skins, $skin, default, border, default), 3%);
        border-left: 1px solid array-get($config, input, skins, $skin, default, border, default);
      }
    }

    .custom-file-input:focus + .custom-file-label {
      border-color: array-get($config, input, skins, $skin, default, border, focus);
      color: array-get($config, input, skins, $skin, default, color, focus);
      @include shadow(none);
    }

    &.emma-custom-file {
      .custom-file-label {
        &.m-input--solid,
        &.emma-input__primary {
          background-color: array-get($config, input, skins, $skin, solid, bg, default);
          border-color: array-get($config, input, skins, $skin, solid, border, default);
          color: array-get($config, input, skins, $skin, solid, color, default);
          @include input-placeholder(array-get($config, input, skins, $skin, solid, color, placeholder));
        }

        &::after {
          content: 'Explorar';
        }
      }

      .custom-file-input:focus + .custom-file-label.m-input--solid,
      .custom-file-input:focus + .custom-file-label.emma-input__primary {
        border-color: array-get($config, input, skins, $skin, default, border, focus);
        color: array-get($config, input, skins, $skin, default, color, focus);
        @include shadow(none);
      }
    }
  }

  .ng-dirty.ng-invalid,
  .ng-touched.ng-invalid {
    > .form-control.m-input--solid,
    > .form-control.emma-input__primary {
      border-color: array-get($config, input, skins, $skin, ko, border, default);
      color: array-get($config, input, skins, $skin, ko, bg, default);
      z-index: 3;
    }
  }

  emma-multitypeinput,
  emma-multi-input,
  emma-input,
  emma-input-color,
  emma-input-file-content,
  emma-input-file-image {
    &.ng-dirty.ng-invalid,
    &.ng-touched.ng-invalid {
      .form-control.m-input--solid,
      .form-control.emma-input__primary {
        border-color: array-get($config, input, skins, $skin, ko, border, default);
        color: array-get($config, input, skins, $skin, ko, bg, default);
        z-index: 3;
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--input-base();

//== Component Skin
@include m-build-component--input-skin($m--forms);
