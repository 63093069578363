//
//** Alert Customization
// 

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--alert-base() {
    //== Base
    .alert {
        display: flex;
        position: relative;
        align-items: center;
        padding-top: 0.85rem;
        padding-bottom: 0.85rem;
        padding-left: 1.5rem;
        padding-right: 2.5rem;


        .close  {
            @include m-generate-close-emma-icon();
            padding-right: 1rem;
        }

        .m-alert__close.m-alert__close {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;

            .close {
                padding-bottom: 0;
                padding-top: 0.35rem;
                top: 0;
            }
        }

        &.m-alert--square {
            @include border-radius(0);
        }

        &.m-alert--icon {
            display: flex;

            .m-alert__icon {
                width: auto;

                i.la-spin {
                    display: inline-block;
                }
            }
        }

        .m-alert__text {
            flex: 1;
            word-break: break-word;

            p:last-child {
                margin-bottom: 0;
            }
        }

        .m-alert__actions {
            flex: 0 1 auto;
        }

        &.m-alert--small.m-alert--icon {

            .m-alert__icon {
                padding: .5rem 1rem;
            }

            .m-alert__text {
                padding: .5rem;
            }

            .m-alert__actions {
                padding: .5rem;
            }
        }
    }
}

//== Component Base
@include m-build-component--alert-base();