.tiktok-business-button {
    cursor: pointer;
    background: #000;
    display: inline-block;
    height: 40px;
    padding: 5px 20px 5px 14px;
  }
  
  .tiktok-business-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .tiktok-business-button.tiktok-business-button.tiktok-business-button span {
    vertical-align: middle;
    color: #fff;
    font-weight: 500;
  }
  
  .tiktok-business-button .tiktok-icon {
    width: 28px;
    fill: #fff;
    vertical-align: middle;
    padding-right: 10px;
    padding-bottom: 3px;
  }
  