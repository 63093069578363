//
//** EMMA QRCode
//

.qrLinkContainer {
  .qrDownloadIcon.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
  }

  &:hover {
    .qrDownloadIcon.btn.btn {
      opacity: 0.8;
      background-color: #fff !important;
    }
  }
}

qrcode {
  display: block;

  .qrcode {
    text-align: center;

    > canvas,
    > img {
      margin: 0 auto;
    }
  }

  &.qrcode-fluid-width .qrcode {
    > canvas,
    > img {
      width: 100%;
      height: auto;
    }
  }

  &.qrcode-half-width .qrcode {
    > canvas,
    > img {
      width: 50%;
      height: 50%;
    }
  }

  &.qrcode-limit-width .qrcode {
    > canvas,
    > img {
      max-width: 125px;
      max-height: 125px;
    }
  }

  &.qrcode-table-preview .qrcode {
    > canvas,
    > img {
      max-width: 42px;
      max-height: 42px;
    }
  }
}
