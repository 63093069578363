//
//** Global Skin
//

$skin: get-default-skin();

//
//** Global Helper Classes
//

.m--block {
  display: block !important;
}

.m--inline-block {
  display: inline-block !important;
}

.m--no-padding {
  padding: 0 !important;
}

.m--no-left-padding {
  padding-left: 0 !important;
}

.m--no-right-padding {
  padding-right: 0 !important;
}

.m--no-top-padding {
  padding-top: 0 !important;
}

.m--no-bottom-padding {
  padding-bottom: 0 !important;
}

.m--no-margin {
  margin: 0 !important;
}

.m--no-left-margin {
  margin-left: 0 !important;
}

.m--no-right-margin {
  margin-right: 0 !important;
}

.m--no-top-margin {
  margin-top: 0 !important;
}

.m--no-bottom-margin {
  margin-bottom: 0 !important;
}

.m--auto-left-margin {
  margin-left: auto !important;
}

.m--auto-right-margin {
  margin-right: auto !important;
}

.m--auto-top-margin {
  margin-top: auto !important;
}

.m--auto-bottom-margin {
  margin-bottom: auto !important;
}

//== Font Weights
@each $name, $weight in $m--font-weights {
  .emma--font-#{$name} {
    font-weight: $weight !important;
  }
}

//== Grupo de botones con separación

.m--btn-group-action > .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

//== Estilos para bloquear Portlets

//== Acerca el mensaje de alerta al Portlet inmediatamente inferior.

.emmaMissingAlert {
  display: block;
  padding-top: 15px;

  .emma-alert-nodata {
    background-color: get-color(light, panel, '-');
    text-align: center;
    padding: 5px 7px;

    > i {
      font-size: 1.6rem;
      margin-right: 10px;
    }

    > strong {
      margin-right: 10px;
    }
  }
}

.alertAttachedPortlet {
  display: flex;
  margin-bottom: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
}

//== bloquea el Portlet.

.blockedPortlet {
  position: relative;
  cursor: not-allowed;
}

.blockedPortlet::before {
  content: ' ';
  display: block;
  background-color: rgba(250, 250, 250, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

//== Fin de Estilos para bloquear Portlets

//== Estilos para gráficas sin datos

.noDataGraphMesage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  text-align: center;

  > i {
    font-size: 160px;
    opacity: 0.05;
    font-weight: bold;
    position: absolute;
    left: calc(50% - 80px);
    top: calc(50% - 84px);
  }
}

//== Fin de Estilos para gráficas sin datos

//== Estilos para error y anuncio de Página

.announceEmma-container {
  display: flex;
  padding: 15px;
  margin: 0 auto;
  flex-wrap: nowrap;

  .announceEmma-image {
    padding: 10px 20px;
    width: 150px;
    height: 150px;
    text-align: center;

    img {
      max-width: 100px;
      width: 100px;
      padding-right: 8px;
    }

    .errorEmma-image-exclamation,
    .heavyqueryEmma-image-exclamation {
      font-size: 10px;
      padding-top: 10px;
      font-style: italic;
      color: get-color($skin, muted);

      > span {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .announceEmma-message {
    flex: 1 100%;

    .announceEmma-message__title-container {
      .announceEmma-message__author {
        color: get-color($skin, muted);
        font-size: 14px;
        font-weight: 400;
        font-style: italic;
      }
    }

    .announceEmma-message__error {
      font-size: 12px;
      word-break: break-all;
    }
  }

  &.errorEmma {
    .announceEmma-image {
      padding: 20px;
    }

    &:hover {
      .errorEmma-image-exclamation {
        > span {
          visibility: visible;
          opacity: 1;

          &:first-child {
            transition: all 0.3s ease-in-out;
          }

          &:last-child {
            transition: all 0.3s ease-in-out 1s;
          }
        }
      }
    }
  }

  &.heavyqueryEmma {
    .announceEmma-image {
      padding: 0 20px 20px;

      img {
        width: 75px;
      }
    }

    &:hover {
      .heavyqueryEmma-image-exclamation {
        > span {
          visibility: visible;
          opacity: 1;

          &:first-child {
            transition: all 0.3s ease-in-out;
          }

          &:last-child {
            transition: all 0.3s ease-in-out 1s;
          }
        }
      }
    }
  }
}

@include mobile {
  .announceEmma-container {
    flex-wrap: wrap;

    .announceEmma-image {
      width: 100%;
      height: auto;
      padding: 0 0 20px;

      img {
        width: 70px;
      }

      .errorEmma-image-exclamation {
        display: none;
      }
    }

    .announceEmma-message {
      .announceEmma-message__title-container {
        text-align: center;

        h5 {
          display: inline-block;
        }

        .announceEmma-message__title {
          display: inline-block;
        }

        .announceEmma-message__author {
          display: block;
          text-align: left;
          padding-top: 5px;
        }
      }
    }
  }
}

.dataMonsterGraphMessage {
  text-align: center;

  .dataMonsterGraphMessage-img {
    > img {
      max-width: 55px;
    }
  }

  .dataMonsterGraphMessage-text {
    > p {
      max-width: 550px;
      display: inline-block;
      padding-top: 25px;
    }
  }
}

//== Fin de Estilos para error y anuncio de Página

//== Estilos para emma-button-bar

.emma-button-bar {
  background-color: array-get($m--state-colors, metal, base);
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

//== Fin de Estilos para emma-button-bar

//== Estilos para Loading de EMMA

.emmaLoading {
  .emmaLoading-overlay {
    z-index: 8;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(250, 250, 250, 0.75);
    cursor: wait;
    position: absolute;
  }
  .emmaLoading-spinner {
    z-index: 9;
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100%;
    top: 50%;
    left: 0;
    text-align: center;
    color: #000;
    border: 0;
    cursor: wait;

    img.svgImage {
      max-width: 130px;
      width: 100%;
      margin: 40px 0 20px;
    }
  }

  &.emmaLoading--error {
    .emmaLoading-overlay {
      background-color: rgba(250, 250, 250, 1);
      opacity: 1;
      cursor: default;
    }
    .emmaLoading-message {
      color: rgb(200, 30, 10);
    }
    .emmaLoading-spinner {
      cursor: default;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

//== Fin de Estilos para Loading de EMMA

//== Helpers in responsive

@include desktop {
  .m--align-right-desktop {
    text-align: right !important;
  }

  .m--align-center-desktop {
    text-align: center !important;
  }

  .m--align-left-desktop {
    text-align: left !important;
  }
}

@include tablet {
  .m--align-right-tablet {
    text-align: right !important;
  }

  .m--align-center-tablet {
    text-align: center !important;
  }

  .m--align-left-tablet {
    text-align: left !important;
  }
}

@include tablet-and-mobile {
  .m--align-right-tablet-and-mobile {
    text-align: right !important;
  }

  .m--align-center-tablet-and-mobile {
    text-align: center !important;
  }

  .m--align-left-tablet-and-mobile {
    text-align: left !important;
  }
}

@include mobile {
  .m--align-right-mobile {
    text-align: right !important;
  }

  .m--align-center-mobile {
    text-align: center !important;
  }

  .m--align-left-mobile {
    text-align: left !important;
  }
}

//== Visible Helpers

.m--visible-desktop-flex,
.m--visible-tablet-flex {
  display: none !important;
}

@include desktop {
  .m--visible-desktop-flex {
    display: flex !important;
  }
}

@include tablet {
  .m--visible-tablet-flex {
    display: flex !important;
  }
}
