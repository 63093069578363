//
//** Bootstrap Datetimepicker Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--datetimepicker-base() {
    .datetimepicker  {
    	width: 265px;
    	padding: 10px;

    	&.datetimepicker-orient-top {
    		margin-top: 8px;
    	}

    	table {
    		width: 100%;
    	}

    	td, th {
    		width: 35px;
    		height: 35px;
    		@include border-radius(3px);
    		font-weight: get-font-weight(regular);
    	}

    	thead {
    		th {
    			&.prev,
    			&.switch,
    			&.next {
    				font-weight: get-font-weight(bolder);
    			}

    			&.dow {
    				font-weight: get-font-weight(bolder);
    			}

    			&.next,
    			&.prev {
    				> span {
    					display: inline-block;
					    font: normal normal normal 16px/1 "LineAwesome";
					    text-decoration: inherit;
					    text-rendering: optimizeLegibility;
					    text-transform: none;
					    -moz-osx-font-smoothing: grayscale;
					    -webkit-font-smoothing: antialiased;
							font-size: get-font-size(regular, '-');
    				}
    			}

    			&.next {
    				> span {
    					&:before {
    						content: "\f112";
    					}
    				}
    			}

    			&.prev {
    				> span {
    					&:before {
    						content: "\f111";
    					}
    				}
    			}
    		}
    	}

    	tbody {
    		tr > td {
    			&.day {

	    			&.today {
	    				position: relative;

	    				&:before {
						  	content: '';
						  	display: inline-block;
						  	border: solid transparent;
						  	border-width: 0 0 7px 7px;
						  	border-bottom-color: #ffffff;
						  	border-top-color: rgba(0, 0, 0, 0.2);
						  	position: absolute;
						  	bottom: 4px;
						  	right: 4px;
						}
	    			}	
    			}    				
    		}
    	}

    	tfoot {
    		tr > th {
    			&.today,
    			&.clear {
    				@include border-radius(3px);
    				font-weight: get-font-weight(bolder);  
    			}
    		}
    	}
    }

    .input-daterange {
    	.input-group-addon {
    		min-width: 44px;
   		}
   	}
}

//== Component Skin
@mixin m-build-component--datetimepicker-skin($skin) {
    .datetimepicker  {

        thead {
            th {
                &.prev,
                &.switch,
                &.next {

                    &:hover {
                        background: get-color($skin, panel);
                    }
                }
            }
        }

        tbody {
            tr > td {
                &.day {
                    color: get-color($skin, regular);

                    &:hover {
                        background: get-color($skin, panel);
                    }

                    &.old {
                        color: get-color($skin, regular, '--');
                    }

                    &.new {
                        color: get-color($skin, regular);
                    }    

                    &.selected,
                    &.active {
                        background: get-state-color(primary, base);
                        color: get-state-color(primary, inverse);
                    }   

                    &.today {
                        background: lighten(get-state-color(primary, base), 10%);
                        color: get-state-color(primary, inverse);
                        position: relative;

                        &:before {
                            border-bottom-color: #ffffff;
                            border-top-color: rgba(0, 0, 0, 0.2);
                        }
                    }   

                    &.range {
                        background: get-color($skin, panel, '-');
                    }
                }       

                span.hour,
                span.minute,
                span.month {
                    color: get-color($skin, regular);

                    &.focused,
                    &:hover {
                        background: get-color($skin, panel);
                    }

                    &.active:hover,
                    &.active {
                        background: get-state-color(primary, base);
                        color: get-state-color(primary, inverse);
                    }
                }               
            }
        }

        tfoot {
            tr > th {
                &.today,
                &.clear {
                    &:hover {
                        background-color: get-color($skin, panel);
                    }
                }
            }
        }

        &.datetimepicker-inline {
            border: 1px solid get-color($skin, panel, '-');
        }
    }
}


//==== Generate Component ====//

//== Component Base
@include m-build-component--datetimepicker-base();

//== Component Skin - Light
@include m-build-component--datetimepicker-skin(light);