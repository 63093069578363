//
//** EMMA Switch Component
//

//== Component Base
@mixin m-build-component--emma-switch-base() {
  @each $name, $color in $m--state-colors {

    .emma-ui-switch-#{$name} {

      display: inline-block;
      position: relative;

      .switch {
        border: $btn-border-width solid transparent;

        > small {
          box-shadow: 0 2px 8px 2px rgba(0, 0, 0, .2);
          
        }

        &.switch-medium {
          > small {
            width: 24px;
            height: 24px;
            top: 3px;
            right: calc(100% - 27px);
          }

          &.checked {
            > small {
              right: 3px;
              left: auto;
            }
          }
        }

        &.switch-small {
          > small {
            width: 16px;
            height: 16px;
            top: 2px;
            right: calc(100% - 18px);
          }

          &.checked {
            > small {
              right: 2px;
              left: auto;
            }
          }
        }

        &:focus {
          outline: 0;
        }
      }

      &.ng-dirty.ng-invalid::before,
      &.ng-touched.ng-invalid::before {
        content: '';
        position: absolute;
        left: -15px;
        top: 10px;
        height: 10px;
        width: 10px;
        border-radius: 50px;
      }
    }

    emma-switch {

      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {

        .emma-ui-switch::before {
          content: '';
          position: absolute;
          left: -15px;
          top: 10px;
          height: 10px;
          width: 10px;
          border-radius: 50px;
        }
      }
    }
  }
}

@mixin m-build-component--emma-switch-skin($config) {
  $skin: get-default-skin();

  @each $name, $color in $m--state-colors {

    .emma-ui-switch-#{$name} {

      .switch {
        background-color: get-state-color(metal, base);
        border-color: get-state-color(metal, base);

        &.checked {
          background-color: array-get($color, base);
          border-color: array-get($color, base);
        }
      }

      &.ng-dirty.ng-invalid::before,
      &.ng-touched.ng-invalid::before {
        background-color: array-get($config, input, skins, $skin, ko, border, default);
      }
    }

    emma-switch {

      &.ng-dirty.ng-invalid,
      &.ng-touched.ng-invalid {

        .emma-ui-switch::before {
          background-color: array-get($config, input, skins, $skin, ko, border, default);
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emma-switch-base();

//== Component Skin
@include m-build-component--emma-switch-skin($m--forms);
