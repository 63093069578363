//== Component Base
@mixin m-build-component--emmafilterbar-base() {
  .emmaFilterBar {
    display: block;
    margin-left: -30px;
    margin-right: -30px;
    padding: 10px 30px;

    &.emmaFilterBar--sticky {
      position: -webkit-sticky;
      position: sticky;
      z-index: 12;
      box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);

      @include transition(array-get($m-config-header, header, self, transition, general));

      top: 120px;
    }

    .emmaFilterBar-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .emmaFilterBar-filters,
      .emmaFilterBar-views {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .emmaFilterBar-filters {
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
      }

      .emmaFilterBar-views {
        margin-left: auto;
      }

      .emmaFilterBar-block {
        padding: 0 10px;

        .emmaFilterBar-views {
          position: relative;

          .emmaFilterBar-views__popup {
            right: 0;
            top: 100%;

            .emmaFilterBar-views__available {
              max-height: 300px;
              overflow-y: auto;
              margin-left: -10px;
              margin-right: -10px;
              background:
                /* Shadow covers */
                linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
                linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
                /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
                radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
              background-repeat: no-repeat;
              background-color: #fff;
              background-size:
                100% 30px,
                100% 30px,
                100% 10px,
                100% 10px;
              background-attachment: local, local, scroll, scroll;

              .emmaFilterBar-views__unit {
                display: flex;
                align-items: center;
                max-width: 300px;

                .emmaFilterBar-views__name {
                  display: block;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  width: 100%;
                  cursor: pointer;
                  padding: 0.7rem 1.2rem;
                }

                .emmaFilterBar-views__delete {
                  margin-left: auto;
                  margin-right: 5px;
                  flex-shrink: 0;
                }
              }
            }
          }
        }

        .emmaFilterBar-component__label {
          display: inline-block;
          position: relative;
          cursor: pointer;
          padding: 0.5rem 1rem;

          @include transition($btn-transition);
          @include border-radius($btn-border-radius);

          > i {
            font-size: 0.8rem;
            vertical-align: middle;
          }

          &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            top: 1rem;
            left: 0;
            width: 0%;

            @include transition(width 0.3s ease);
          }

          &:hover {
            &::after {
              width: 100%;

              @include opacity(0.3);
            }
          }
        }

        .emmaFilterBar-component__popup,
        .emmaFilterBar-views__popup {
          position: absolute;
          min-width: 250px;
          padding: 10px 10px 0;
          z-index: 12;

          @include border-radius(
            array-get($m-config-menu-header, desktop, build, layout, item, submenu, self, border-radius)
          );
        }

        .emmaFilterBar-component--add {
          .emmaFilterBar-component__label {
            > i {
              font-size: 1.3rem;
              vertical-align: text-bottom;
            }
          }
        }
      }
    }
  }

  .m-header--fixed.m-header--minimize-on {
    .emmaFilterBar {
      &.emmaFilterBar--sticky {
        @include transition(array-get($m-config-header, header, self, transition, hide));

        top: 55px;
      }
    }
  }

  @include tablet-and-mobile {
    .emmaFilterBar {
      margin-left: -20px;
      margin-right: -20px;
      padding: 10px 20px;

      &.emmaFilterBar--sticky {
        top: 70px;
      }
    }
  }
}

@mixin m-build-component--emmafilterbar-skin($skin) {
  //== Base color

  // $base_color: array-get($m--state-colors, metal, base); //#f4f3f8
  $base_color: lighten(#ebedf2, 3%); //#f4f3f8
  $base_color_darken: darken($base_color, 7.5%);

  .emmaFilterBar {
    background-color: $base_color_darken;

    .emmaFilterBar-container {
      .emmaFilterBar-block {
        .emmaFilterBar-views {
          .emmaFilterBar-views__popup {
            .emmaFilterBar-views__available {
              .emmaFilterBar-views__unit {
                &:hover {
                  background: get-color($skin, panel, '-');
                }

                .emmaFilterBar-views__name {
                  &:hover {
                    background: get-color($skin, panel, '-');
                    color: get-color($skin, link);
                  }
                }
              }
            }
          }
        }

        .emmaFilterBar-component__label {
          &:hover {
            &::after {
              border-bottom: 1px solid darken(get-state-color(skudodarkblue, base), 10%);
            }
          }
        }

        .emmaFilterBar-component__popup,
        .emmaFilterBar-views__popup {
          background-color: array-get(
            $m-config-menu-header,
            desktop,
            build,
            skins,
            $skin,
            item,
            submenu,
            self,
            bg-color
          );

          @include shadow(
            array-get($m-config-menu-header, desktop, build, skins, $skin, item, submenu, self, box-shadow)
          );
        }

        .emmaFilterBar-component--empty {
          .emmaFilterBar-component__label {
            color: get-color($skin, regular);
            font-weight: get-font-weight(normal);
          }
        }

        .emmaFilterBar-component--open {
          .emmaFilterBar-component__label {
            background-color: darken($base_color_darken, 10%);
            color: get-color($skin, regular);
            font-weight: get-font-weight(bold);
          }
        }

        .forze-bg-color-ng-select {
          .ng-select.ng-select-opened {
            .ng-select-container {
              background-color: darken($base_color_darken, 10%);
              color: get-color($skin, regular);

              .ng-input {
                > input {
                  color: get-color($skin, regular);
                }
              }
            }
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmafilterbar-base();

//== Component Skin
@include m-build-component--emmafilterbar-skin(light);
