//
//** Scroll Top Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--nav-scroll-top-base($layout) {
  .m-scroll-top {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 40px;
    left: 20px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    display: none;
    padding-top: 9px;
    z-index: 110;

    @include border-radius(100%);

    > i {
      font-size: get-font-size(icon);
    }

    &:hover {
      text-decoration: none;
    }

    .m-scroll-top--shown & {
      display: block;
    }
  }
}

//== Component Skin
@mixin m-build-component--nav-scroll-top-skin($layout, $skin) {
  @include component-skin(m-scroll-top, $skin) {
    background: array-get($layout, bg-color);
    @include shadow(get-shadow($skin));

    > i {
      color: get-color($skin, icon);
    }

    &:hover {
      > i {
        color: get-brand-color();
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--nav-scroll-top-base(array-get($m--nav, scroll-top));

//== Component Skin - Light
@include m-build-component--nav-scroll-top-skin(array-get($m--nav, scroll-top, skins, light), light);

//== Component Skin - Dark
@include m-build-component--nav-scroll-top-skin(array-get($m--nav, scroll-top, skins, dark), dark);
