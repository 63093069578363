.fb-share-button {
  cursor: pointer;
  background: #3b5998;
  border-radius: 3px;
  display: inline-block;
  height: 42px;
  border: none;
  padding: 5px 20px 5px 14px;
}

.fb-share-button:hover {
  background: #213a6f;
}

.fb-share-button.fb-share-button.fb-share-button span {
  vertical-align: middle;
  color: #fff;
  font-weight: 500;
}

.fb-share-button .facebook-icon {
  width: 28px;
  fill: #fff;
  vertical-align: middle;
  padding-right: 10px;
  padding-bottom: 3px;
}
