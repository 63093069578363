//
//** Over-Framework Buttons Component
//

//== Component Base
@mixin m-build-component--buttons-default-base() {

  .btn {
    &.btn-block {
      display: block;
      width: 100%;
    }

    &.btn-block + &.btn-block {
      margin-top: .5rem
    }
  }
}

//== Build Component Base
@include m-build-component--buttons-default-base();
