//== Component Base
@mixin m-build-component--markdown-base() {

  markdown {
    > ol {
      padding-left: 0;
    }
  }
}


//==== Generate Component ====//

//== Component Base
@include m-build-component--markdown-base();

//== Component Skin
