//== Component Base
@mixin m-build-component--emmainput-base() {

  emma-input {
    position: relative;

    .emma-input {
      position: relative;

      .emma-input__suggestions {
        display: block;
        position: absolute;
        min-width: 100px;
        width: 100%;
        top: 100%;
        padding: 0;
        z-index: 10;
        overflow-x: hidden;
        max-height: 30vh;
        overflow-y: auto;
        list-style-type: none;

        &.emma-input__suggestions--top {
          top: auto;
          bottom: 100%;
          margin-top: 1rem;
          margin-bottom: 0;
        }

        &.emma-input__suggestions--botom {
          top: 100%;
          bottom: auto;
          margin-top: 0;
          margin-bottom: 1rem;
        }

        @include border-radius(
          array-get(
            $m-config-menu-header,
            desktop,
            build,
            layout,
            item,
            submenu,
            self,
            border-radius
          )
        );

        > li {
          cursor: pointer;
          font-size: 1rem;
          padding: 0.7rem 1.2rem;
        }
      }
    }

    .helpMessageAtError {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

@mixin m-build-component--emmainput-skin($skin) {
  .emma-input {
    .emma-input__suggestions {
      background-color: array-get(
        $m-config-menu-header,
        desktop,
        build,
        skins,
        $skin,
        item,
        submenu,
        self,
        bg-color
      );

      @include shadow(
        array-get(
          $m-config-menu-header,
          desktop,
          build,
          skins,
          $skin,
          item,
          submenu,
          self,
          box-shadow
        )
      );

      > li {

        > .emma-input__suggestion-help {
          display: none;
          color: get-color($skin, regular);
          font-size: 12px;
          font-style: italic;
          font-weight: array-get($m--root-font, weight);
        }

        &.active {
          background: get-color($skin, panel, '-');
          color: get-color($skin, link);

          > .emma-input__suggestion-help {
              display: block;
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmainput-base();

//== Component Skin
@include m-build-component--emmainput-skin(light);
