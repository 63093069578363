//== Component Base
@mixin m-build-component--emmachart-base() {

  emma-chart {

    &.emma-chart--stackbar {

      .amChartsLegend {
        max-height: 100px !important;
        overflow-y: auto !important;
      }
    }
    .emma-chart--map a {
      display: none !important;
    }
  }
}


//==== Generate Component ====//

//== Component Base
@include m-build-component--emmachart-base();
