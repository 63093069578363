//== General Mode
.m-page--overflow {
  overflow-x: hidden;
}

.m-page {
  min-height: 100vh;
  // hide page when page loading is enabled
  .m-page--loading & {
    display: none !important;
  }
}

//== Desktop Mode
@include desktop {
  .m-page {
    // page container
    .m-page__container {
      position: relative;
      // wide layout mode
      padding: 0 array-get($m-config-base, page, container, padding, wide, x, desktop);

      // fluid layout mode
      .m-page--fluid & {
        padding: 0 array-get($m-config-base, page, container, padding, fluid, x, desktop);
      }
    }
  }
}

//== Desktop Mode
@include tablet-and-mobile {
  .m-page {
    // page container
    .m-page__container {
      position: relative;
      padding: 0 array-get($m-config-base, page, container, padding, wide, x, mobile);

      .m-page--fluid & {
        padding: 0 array-get($m-config-base, page, container, padding, fluid, x, mobile);
      }
    }
  }
}
