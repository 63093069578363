//
//** User Profile Card
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--cards-base() {

    .emma-accordion {

        .accordion-item.ng-star-inserted {

            border-radius: 0;
            border: 0;

            &:first-of-type {
                border:0;
            }

            &:last-of-type {
                border: 0;
            }

            .accordion-header {
                border-radius: 0;
                padding: 0;
                margin-bottom: 0;

                > button {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    border-radius: 0;
                    padding: 1rem 2rem;
                    width: 100%;

                    .m-accordion__item-icon {
                        margin-right: 1rem;

                        > i {
                            font-size: 2rem;
                        }
                    }

                    .m-accordion__item-title {
                        font-size: 1.2rem;
                    }

                    .m-accordion__item-mode {
                        margin-left: auto;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

//== Component Skin

//==== Generate Component ====//

//== Component Base
@include m-build-component--cards-base();

//== Component Skin - Light

//== Component Skin - Dark
