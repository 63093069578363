//
//** Page block loader
//
//

//== Mixins
@mixin m-build-component--page-loader-base() {
  .m-page-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: array-get($m--page-loader, layout, self, zindex);

    // vertical & horizontal center
    @include flexbox-justify-content(center);
    @include flexbox-align-items(center);

    display: none;

    .m-page--loading & {
      @include flexbox();
    }

    &.m-page-loader--non-block {
      position: fixed;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      width: auto;

      .m-page--loading-non-block & {
        display: inline-block;
      }
    }
  }
}

@mixin m-build-component--page-loader-skin($skin) {
  @include component-skin(m-page-loader, $skin) {
    background: get-color($skin, box);

    &.m-page-loader--non-block {
      background: transparent;
    }
  }
}

//== Build

@include m-build-component--page-loader-base();

@include m-build-component--page-loader-skin(light);
