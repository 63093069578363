//
//** EMMA Switch Component
//

//== Component Base
@mixin m-build-component--emma-quill-base() {
  .ql-picker.ql-custom {
    width: 120px;
  }
  
  .ql-picker.ql-custom .ql-picker-item::before, 
  .ql-picker.ql-custom .ql-picker-label::before {
    content: "Etiquetas";
  }

  .ql-picker.ql-custom .ql-picker-item::before {
    content: attr(data-value);
  }

  @each $name, $color in $m--state-colors {

    .emma-quill-#{$name} {
      // Empty Function
    }
  }

  .ql-toolbar > .ql-custom > * button {
    float: none;
    height: auto;
    width: 100%;
  }
}

@mixin m-build-component--emma-quill-skin($config) {
  $skin: get-default-skin();

  @each $name, $color in $m--state-colors {

    .emma-quill-#{$name} {
      // Empty Function
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emma-quill-base();

//== Component Skin
@include m-build-component--emma-quill-skin($m--forms);
