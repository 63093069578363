//== Component Base
@mixin m-build-component--emmatextarea-base() {
  .emma-textarea--highlight {
    mark {
      padding: 0em;
      position: relative;
      left: 0em;
      border-radius: 0.14em;
    }
  }
}

@mixin m-build-component--emmatextarea-skin($config, $skin) {
  emma-textarea,
  emma-json-editor {
    &.ng-invalid {
      textarea {
        border-color: array-get($config, input, skins, $skin, ko, border, default) !important;
        color: array-get($config, input, skins, $skin, ko, bg, default) !important;
      }
    }
  }

  .emma-textarea--main {
    background-color: array-get($config, input, skins, $skin, solid, bg, default);

    &:focus:not([readonly]) {
      background-color: array-get($config, input, skins, $skin, solid, bg, focus);
    }

    &[disabled] {
      background-color: array-get($config, input, skins, $skin, solid, bg, disabled);
    }

    &:focus[readonly],
    &[readonly] {
      background-color: transparent;
    }
  }

  .emma-textarea--header {
    .char-counter.char-counter__limit {
      .char-counter__number {
        color: get-state-color(warning, base);
      }
    }

    .char-counter__overflow {
      .char-counter__number {
        color: get-state-color(danger, base);
      }
    }
  }

  .emma-textarea--highlight {
    mark {
      background-color: hsla(157, 100%, 32%, 0.25);
      color: hsla(157, 100%, 32%, 1);
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--emmatextarea-base();
@include m-build-component--emmatextarea-skin($m--forms, light);

//== Component Skin
